import AccountBoxSharpIcon from '@mui/icons-material/AccountBoxSharp';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { Box, Tab, Tabs, useMediaQuery, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { capitalCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import EllapsedPost from './ellapsed-approval/ellapsed-post.component';
import PendingApproval from './pending-approval/pending-approval.component';
import RejectedApproval from './rejected-post/rejected-approval.component';
import ShareIcon from '@mui/icons-material/Share';
import SharePublicApprovalPopup from '../../../src/components/public-approval/share-approval.popup';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { useToaster } from 'src/utils/toaster/toasterContext';
import { CommonHelper } from 'src/utils/commonHelper';

const Approval = () => {
    const [currentTab, setCurrentTab] = useState('pending approval');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [shareApprovalPostsModal, setShareApprovalPostsModal] = useState(false);
    const [popoverElement, setPopoverElement] = useState(false);
    const [isLinkPresent, setLinkPresent] = useState(false);
    const [urlKey, setUrlKey] = useState('');
    const [shareUntil, setShareUntil] = useState('');
    const hasBrandAdminPermission = CommonHelper.CheckForPermissionAvailableForUser(
        CommonHelper.PermissionList.BRAND_ADMIN
    );
    const url = `${window.location.origin}/posts/${urlKey}`;
    const { showToast } = useToaster();

    useEffect(() => {
        hasBrandAdminPermission && getShareApprovalPosts();
    }, []);

    const getShareApprovalPosts = () => {
        const getProps = {
            url: userModuleURL.getShareApprovalPosts,
            successCallback: getShareApprovalPostsSuccessCallback,
            failureCallback: getShareApprovalPostsFailureCallback,
        };
        HttpServices.Get(getProps);
    };

    const getShareApprovalPostsSuccessCallback = (data) => {
        setLinkPresent(data?.is_present);
        setUrlKey(data?.url_key);
        setShareUntil(data?.expire_date);
    };

    const getShareApprovalPostsFailureCallback = (message) => {
        message && showToast(message, 'e');
    };

    const handleOpenMenu = (event) => {
        setPopoverElement(event.currentTarget);
        setShareApprovalPostsModal(true);
    };

    const handleCloseMenu = () => {
        setPopoverElement(null);
        setShareApprovalPostsModal(false);
    };
    const shareApprovalPostProps = {
        popoverElement: popoverElement,
        popoverElement,
        url: url,
        urlKey: urlKey,
        shareUntil: shareUntil,
        handleCloseMenu,
        getShareApprovalPosts,
    };

    const ACCOUNT_TABS = [
        {
            value: 'pending approval',
            icon: <AccountBoxSharpIcon sx={{ width: "20px", height: "20px" }} />,
            component: <PendingApproval />,
        },
        {
            value: 'rejected approval',
            icon: <VpnKeyIcon sx={{ width: "20px", height: "20px" }} />,
            component: <RejectedApproval />,
        },
        {
            value: 'Ellapsed Requests',
            icon: <VpnKeyIcon sx={{ width: "20px", height: "20px" }} />,
            component: <EllapsedPost />,
        }
    ];
    return (
        <>
            <Helmet>
                <title>Approval</title>
            </Helmet>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Tabs
                    value={currentTab}
                    scrollButtons="auto"
                    variant="scrollable"
                    sx={{ gap: '0.5rem', minHeight: '37px' }}
                    allowScrollButtonsMobile
                    onChange={(e, value) => setCurrentTab(value)}
                >
                    {ACCOUNT_TABS.map((tab) => {
                        return <Tab
                            disableRipple
                            key={tab.value}
                            label={isMobile ? "" : capitalCase(tab.value)}
                            icon={tab.icon}
                            value={tab.value}
                            sx={{ flexDirection: 'row', gap: '0.25rem', padding: 0, minHeight: '10px', mr: '0.75rem', fontWeight: 600 }}
                        />
                    })}
                </Tabs>
                {hasBrandAdminPermission && <Button variant='contained' startIcon={<ShareIcon />} onClick={(e) => handleOpenMenu(e)}>{isLinkPresent ? 'Link Already Shared' : 'Share Public Link'}</Button>}
            </Box>
            {hasBrandAdminPermission && <SharePublicApprovalPopup {...shareApprovalPostProps} />}
            <Box sx={{ mb: 2 }} />
            {
                ACCOUNT_TABS.map((tab) => {
                    const isMatched = tab.value === currentTab;
                    return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                })
            }
        </>
    )
}
export default Approval;