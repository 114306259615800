import React, { useEffect, useState } from 'react';
import { Card, Grid,IconButton } from '@mui/material';
import SummaryCommonCard from 'src/components/reports/summary-cards/summaryCommonCard';
import DonutChart from 'src/components/reports/summary-cards/summaryDonutCard';
import HttpServices from 'src/services/httpService';
import { reportModuleURL } from 'src/services/urlService';
import { useToaster } from 'src/utils/toaster/toasterContext';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NoReportCard from 'src/components/reports/summary-cards/noReportCard';
import ReportActionPopup from 'src/pages/reports/reportActionPopup';

const NestedCard = ({ connectedProfileId, startDate, endDate, audienceSummary,viewBy, serviceName,reportId }) => {
    const [isLoading, setLoading] = useState(true);
    const [summaryData, setSummaryData] = useState([]);
    const [open, setOpen] = useState(false); // Ensure open state for popover
    const [anchorEl, setAnchorEl] = useState(null); // For managing popover anchor
    const { showToast } = useToaster();

    useEffect(() => {
        if (connectedProfileId) {
            getAudienceSummary();
        }
    }, [connectedProfileId, startDate, endDate,viewBy]);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handlePopoverClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };


    const getSummaryApi = () => {
        switch (serviceName) {
            case "facebook":
                return reportModuleURL.facebookAudienceSummary;
            case "instagram":
                return reportModuleURL.instagramAudienceSummary;
            case "youtube":
                return reportModuleURL.youtubeAudienceSummary;
            default:
                return null;
        }
    };

    const getAudienceSummary = () => {
        const apiUrl = getSummaryApi();
        if (!apiUrl) return;

        setLoading(true);
        const body = {
            connected_profile_id: connectedProfileId,
            report_id: audienceSummary.id,
            date_range: {
                from: startDate,
                to: endDate,
            },
            view_by: viewBy
        };

        HttpServices.Post({
            url: apiUrl,
            body: body,
            successCallback,
            failureCallback
        });
    };

    const failureCallback = (message) => {
        setLoading(false);
        showToast(message, "e");
        setSummaryData([]);
    };

    const successCallback = (data) => {
        const responseData = data[0]?.data || [];
        setSummaryData(responseData);
        setLoading(false);
    };

    const renderComponent = (data) => {
        switch (data.card_type) {
            case "square":
                return (
                    <Grid item xs={12} sm={6} md={2.5} key={data.id}>
                        <SummaryCommonCard
                            title={data?.title}
                            data={data?.data}
                            percentage={data?.percentage}
                            percentageState={data?.percentage_state}
                            averageData={data?.average_data}
                        />
                    </Grid>
                );
            case "donut":
                return (
                    <Grid item xs={12} sm={6} md={4.5} key={data.id}>
                        <DonutChart
                            title={data?.title}
                            value={data?.value}
                            labels={data?.key}
                        />
                    </Grid>
                );
            case "no-report":
                return (
                    <Grid item xs={12} sm={6} md={2.5} key={data.id}>
                        <NoReportCard />
                    </Grid>
                );
            default:
                return null;
        }
    };

    return (
        <Card sx={{ position: 'relative' }}>
        {isLoading ? (
            <Grid container spacing={2}>
                {Array.from({ length: 4 }).map((_, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <NoReportCard />
                    </Grid>
                ))}
            </Grid>
        ) : (
            <Grid container spacing={2}>
                {summaryData.map((data) => renderComponent(data))}
            </Grid>
        )}
        <IconButton
            onClick={handlePopoverOpen}
            sx={{ position: 'absolute', top: 8, right: 8 }}
        >
            <MoreHorizIcon sx={{ fontSize: 30 }}/>
        </IconButton>
        <ReportActionPopup
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            serviceName={serviceName}
            startDate={startDate}
            endDate={endDate}
            viewBy={viewBy}
            connectedProfileId={connectedProfileId}
            reportId={reportId}
            currReportData={audienceSummary}
            subReportId={audienceSummary.id}
        />
    </Card>
    );
};

export default NestedCard;
