import { Divider, Grid, Typography } from '@mui/material';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import GBusiness from '../../../assets/google-my-business-logo.svg';
import GBusinessEventPreview from '../publishedPostsPostCard/gbusiness-event-preview.component';
import GBusinessOfferPreview from '../publishedPostsPostCard/gbusiness-offer-preview.component';
import GBusinessStandardPreview from '../publishedPostsPostCard/gbusiness-standard-preview.component';

const GoogleMyBusinessPostDetailView = ({
  profile,
  height,
  image_url,
  message,
  callToAction,
  topic_type,
  event,
  offer,
}) => {

  const sideBarWidth = 800;
  const postContent = () => {
    let cardDetails = {
      media_url: image_url,
      description: message,
      callToAction: callToAction,
      event: event,
      topic_type,
      offer: offer,
      profile,
    };
    switch(topic_type){
      case "EVENT":
       return <GBusinessEventPreview {...cardDetails}/>
      case "OFFER":
       return <GBusinessOfferPreview {...cardDetails} /> 
      case "STANDARD":
        return <GBusinessStandardPreview {...cardDetails}/> 
      default : 
        return null 
    }
  };
  return (
    <>
      <StyledComponent.StyledStack sx={{ width: sideBarWidth }}>
        <Grid alignItems="center" display="flex">
          <img alt="Gbusiness_Icon" width={75} height={40} sx={{ fontSize: 20 }} src={GBusiness} />
          <Typography style={{ fontSize: 25, fontWeight: 800 }}>GoogleMyBusiness</Typography>
        </Grid>
        <Divider sx={{ mb: 5 }} />
            <Grid display="flex" alignItems="flex-start" justifyContent="flex-start" sx={{ ml: '1.5rem !important' }}>
              {postContent()}
            </Grid>
      </StyledComponent.StyledStack>
    </>
  );
};


export default GoogleMyBusinessPostDetailView;
