import React, { useState, useEffect } from 'react';
import {
    Card,
    Grid,
    Stack,
    IconButton,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useToaster } from 'src/utils/toaster/toasterContext';
import HttpServices from 'src/services/httpService';
import { reportModuleURL } from 'src/services/urlService';
import DonutChart from 'src/components/reports/summary-cards/summaryDonutCard';
import BestPostCardDetails from 'src/components/reports/summary-cards/bestPostCard';
import TableDonutChart from 'src/components/reports/summary-cards/summaryTableDonutCard';
import SummaryCommonCard from 'src/components/reports/summary-cards/summaryCommonCard';
import NoReportCard from 'src/components/reports/summary-cards/noReportCard';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ReportActionPopup from 'src/pages/reports/reportActionPopup';

const EngagementNestedCard = ({ connectedProfileId, startDate, endDate, viewBy, engagementSummary, serviceName, reportId }) => {
    const theme = useTheme();
    const [isLoading, setLoading] = useState(true);
    const isMobile = useMediaQuery(theme.breakpoints.down('xl'));
    const [open, setOpen] = useState(false); // Ensure open state for popover
    const [anchorEl, setAnchorEl] = useState(null); // For managing popover anchor
    const [postEngagementSummary, setPostEngagementSummary] = useState({
        totalPosts: null,
        totalEngagement: null,
        bestPost: null,
        engagementPostType: null
    });
    const { showToast } = useToaster();

    useEffect(() => {
        if (connectedProfileId) {
            getEngagementSummary();
        }
    }, [connectedProfileId, startDate, endDate]);


    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handlePopoverClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const getSummaryApi = () => {
        switch (serviceName) {
            case "facebook":
                return reportModuleURL.facebookAudienceSummary;
            case "instagram":
                return reportModuleURL.instagramAudienceSummary;
            case "youtube":
                return reportModuleURL.youtubeAudienceSummary;
            default:
                return null;
        }
    };

    const getEngagementSummary = () => {
        const apiUrl = getSummaryApi();
        if (!apiUrl) return;

        setLoading(true);
        const body = {
            connected_profile_id: connectedProfileId,
            report_id: engagementSummary.id,
            date_range: {
                from: startDate,
                to: endDate,
            },
            view_by: viewBy
        };

        HttpServices.Post({
            url: apiUrl,
            body: body,
            successCallback: postEngagementSuccessCallback,
            failureCallback: postEngagementFailureCallback
        });
    };

    const postEngagementFailureCallback = (message) => {
        setPostEngagementSummary({
            totalPosts: null,
            totalEngagement: null,
            bestPost: null,
            engagementPostType: null
        });
        if (message) showToast(message, "e");
        setLoading(false);
    };

    const postEngagementSuccessCallback = (data) => {
        const responseData = data[0]?.data || {};
        setPostEngagementSummary({
            totalPosts: responseData.total_posts,
            totalEngagement: responseData.total_engagement,
            bestPost: responseData.best_post,
            engagementPostType: responseData.engagement_post_type
        });
        setLoading(false);
    };

    return (
        <Card>
            {isLoading ? (
                <Grid container spacing={2}>
                    {Array.from({ length: 4 }).map((_, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <NoReportCard />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6} xl={2.5}>
                        <Stack direction={isMobile ? 'row' : 'column'}>
                            {postEngagementSummary.totalPosts?.card_type !== 'no-report' ? (
                                <SummaryCommonCard
                                    title={postEngagementSummary?.totalPosts?.title}
                                    data={postEngagementSummary.totalPosts.data}
                                    percentage={postEngagementSummary.totalPosts.percentage}
                                    percentageState={postEngagementSummary.totalPosts.percentage_state}
                                    averageData={postEngagementSummary.totalPosts.average_data}
                                />
                            ) : (
                                <NoReportCard />
                            )}
                            {postEngagementSummary.totalEngagement?.card_type !== 'no-report' ? (
                                <SummaryCommonCard
                                    title={postEngagementSummary.totalEngagement.title}
                                    data={postEngagementSummary.totalEngagement.data}
                                    percentage={postEngagementSummary.totalEngagement.percentage}
                                    percentageState={postEngagementSummary.totalEngagement.percentage_state}
                                    averageData={postEngagementSummary.totalEngagement.average_data}
                                />
                            ) : (
                                <NoReportCard />
                            )}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} lg={6} xl={2.5}>
                        {postEngagementSummary.bestPost?.card_type !== 'no-report' ? (
                            <BestPostCardDetails
                                title={postEngagementSummary.bestPost.title}
                                comments={postEngagementSummary.bestPost.comment_count}
                                createdTime={postEngagementSummary.bestPost.created_time}
                                description={postEngagementSummary.bestPost.description}
                                engagement={postEngagementSummary.bestPost.engagement}
                                engagementRate={postEngagementSummary.bestPost.engagement_rate}
                                mediaUrl={postEngagementSummary.bestPost.media_url}
                                link={postEngagementSummary.bestPost.link}
                                reactions={postEngagementSummary.bestPost.reaction}
                                shares={postEngagementSummary.bestPost.share_count}
                            />
                        ) : (
                            <NoReportCard />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={isMobile ? 12 : 6} xl={7}>
                        {postEngagementSummary.engagementPostType?.card_type !== 'no-report' ? (
                            <TableDonutChart
                                title={postEngagementSummary.engagementPostType.title}
                                engagementPostType={postEngagementSummary.engagementPostType.engagement_by_type}
                                value={postEngagementSummary.engagementPostType.value}
                                labels={postEngagementSummary.engagementPostType.key}
                            />
                        ) : (
                            <NoReportCard />
                        )}
                    </Grid>
                </Grid>
            )}
            <IconButton
                onClick={handlePopoverOpen}
                sx={{ position: 'absolute', top: 8, right: 8 }}
            >
                <MoreHorizIcon sx={{ fontSize: 30 }} />
            </IconButton>
            <ReportActionPopup
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                serviceName={serviceName}
                startDate={startDate}
                endDate={endDate}
                viewBy={viewBy}
                connectedProfileId={connectedProfileId}
                reportId={reportId}
                currReportData={engagementSummary}
                subReportId={engagementSummary.id}
            />
        </Card>
    );
};

export default EngagementNestedCard;
