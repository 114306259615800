import { useState, useEffect } from "react";
import FailedPost from "../failed-posts/failedPost.component";
import ScheduledPost from "../scheduledPost/scheduledPost.component";
import AccountBoxSharpIcon from '@mui/icons-material/AccountBoxSharp';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Helmet } from "react-helmet-async";
import { Box, Tab, Tabs, useMediaQuery, Button, useTheme } from "@mui/material";
import { capitalCase } from "change-case";
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { useToaster } from 'src/utils/toaster/toasterContext';
import { CommonHelper } from 'src/utils/commonHelper';
import ShareIcon from '@mui/icons-material/Share';
import SharePublicApprovalPopup from "src/components/public-approval/share-approval.popup";

const PostInfoComponent = () => {
    const [currentTab, setCurrentTab] = useState('Scheduled Posts');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [shareApprovalPostsModal, setShareApprovalPostsModal] = useState(false);
    const [popoverElement, setPopoverElement] = useState(false);
    const [isLinkPresent, setLinkPresent] = useState(false);
    const [urlKey, setUrlKey] = useState('');
    const [shareUntil, setShareUntil] = useState('');
    const hasBrandAdminPermission = CommonHelper.CheckForPermissionAvailableForUser(
        CommonHelper.PermissionList.BRAND_ADMIN
    );
    const url = `${window.location.origin}/posts/${urlKey}`;
    const { showToast } = useToaster();

    useEffect(() => {
        hasBrandAdminPermission && getShareApprovalPosts();
    }, []);

    const getShareApprovalPosts = () => {
        const getProps = {
            url: userModuleURL.getShareApprovalPosts,
            successCallback: getShareApprovalPostsSuccessCallback,
            failureCallback: getShareApprovalPostsFailureCallback,
        };
        HttpServices.Get(getProps);
    };

    const getShareApprovalPostsSuccessCallback = (data) => {
        setLinkPresent(data?.is_present);
        setUrlKey(data?.url_key);
        setShareUntil(data?.expire_date);
    };

    const getShareApprovalPostsFailureCallback = (message) => {
        message && showToast(message, 'e');
    };

    const handleOpenMenu = (event) => {
        setPopoverElement(event.currentTarget);
        setShareApprovalPostsModal(true);
    };

    const handleCloseMenu = () => {
        setPopoverElement(null);
        setShareApprovalPostsModal(false);
    };
    const shareApprovalPostProps = {
        popoverElement: popoverElement,
        popoverElement,
        url: url,
        urlKey: urlKey,
        shareUntil: shareUntil,
        handleCloseMenu,
        getShareApprovalPosts,
    };

    const ACCOUNT_TABS = [
        {
            value: 'Scheduled Posts',
            icon: <AccountBoxSharpIcon sx={{ width: 25, height: 25, mb:'0 !important' }} />,
            component: <ScheduledPost />
        },
        {
            value: 'Failed Posts',
            icon: <ManageAccountsIcon sx={{ width: 25, height: 25, mb:'0 !important' }} />,
            component: <FailedPost />
        }
    ];
    return (
        <>
            <Helmet>
                <title>Post Info</title>
            </Helmet>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Tabs
                    value={currentTab}
                    scrollButtons="auto"
                    variant="scrollable"
                    sx={{  gap:'0.5rem', minHeight:'37px' }}
                    allowScrollButtonsMobile
                    onChange={(e, value) => setCurrentTab(value)}
                >
                    {ACCOUNT_TABS.map((tab) => {
                        return <Tab sx={{flexDirection:'row', gap:'0.25rem', padding:0, minHeight:'10px', mr:'0.75rem', fontWeight:600}} disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
                    })}
                </Tabs>
                {hasBrandAdminPermission && <Button variant='contained' startIcon={<ShareIcon />} onClick={(e) => handleOpenMenu(e)}>{isLinkPresent ? 'Link Already Shared' : 'Share Public Link'}</Button>}
            </Box>
            {hasBrandAdminPermission && <SharePublicApprovalPopup {...shareApprovalPostProps} />}
            <Box sx={{ mb: 2 }} />
            {ACCOUNT_TABS.map((tab) => {
                const isMatched = tab.value === currentTab;
                return isMatched && <Box key={tab.value}>{tab.component}</Box>;
            })}
        </>
    );
}
export default PostInfoComponent;