import { MessageOutlined as MessageOutlinedIcon, Delete as DeleteIcon, Send as SendIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Card, Paper, Stack, TextField, IconButton, Typography, Grid, useMediaQuery } from '@mui/material';
import Rating from '@mui/material/Rating';
import { useTheme } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import EditIcon from '@mui/icons-material/Edit';
import { useRightSidebar } from 'src/layouts/right-side-bar/rightSidebarProvider';
import HttpServices from 'src/services/httpService';
import { postsModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import DynamicHeightComponent from 'src/utils/dynamicSize.component';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import ThreeDotLoader from 'src/utils/Loader/threeDotLoader.compontent';
import DeleteCommentIcon from '../../assets/deletecomment.svg';
import NoMessage from '../../assets/app-image/no-message.svg';


const ReviewsGrid = ({ gBusinessId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { showToast } = useToaster();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [height, setHeight] = useState(window.innerHeight - 260);
  const [isLoading, setLoading] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [childComment, setChildComment] = useState('');
  const [childCommentId, setChildCommentId] = useState(null);
  const [isSendLoading, setSendLoading] = useState(false);
  const [pagination, setPagination] = useState('');
  const [loaderReviews, setLoaderReviews] = useState(false);

  useEffect(() => {
    if (gBusinessId) {
      getGBusinessReview();
    }
  }, [gBusinessId]);

  const getGBusinessReviewSuccessCallback = (data, message) => {
    setReviews(data.reviews);
    setPagination(data.pagination);
    setLoading(false);
  };
  const getNextGBusinessReviewSuccessCallback = (data, message) => {
    setLoaderReviews(false);
    let updatedReviewss = [...reviews, ...data.reviews];
    setReviews(updatedReviewss);
    setPagination(data.pagination);
  };
  const addReviewSuccessCallback = (data, message) => {
    const updatedReviews = reviews.map((review) => {
      if (review.reviewId === childCommentId) {
        return { ...review, reviewReply: { comment: childComment, updateTime: new Date() } };
      }
      return review;
    });
    setReviews(updatedReviews);
    setSendLoading(false);
    setChildComment('');
    setChildCommentId(null);
  };
  const deleteReviewSuccessCallback = (data, message) => {
    setChildComment('');
    setChildCommentId(null);
    setSendLoading(false);
  };

  const addReviewFailureCallback = (message) => {
    message && showToast(message, 'e');
    setLoading(false);
    setSendLoading(false);
  };

  const getGBusinessReviewFailureCallback = (message) => {
    setReviews([]);
    message && showToast(message, 'e');
    setLoading(false);
    setLoaderReviews(false);
    setChildComment('');
    setChildCommentId(null);
  };

  const getGBusinessReview = () => {
    setLoading(true);
    let getProps = {
      url: postsModuleURL.fetchAllReviews + gBusinessId,
      successCallback: getGBusinessReviewSuccessCallback,
      failureCallback: getGBusinessReviewFailureCallback,
    };
    HttpServices.Get(getProps);
  };
  const getNextGBusinessReview = () => {
    setLoaderReviews(true);
    let getProps = {
      url: `${postsModuleURL.fetchAllReviews}${gBusinessId}&pagination=${pagination}`,
      successCallback: getNextGBusinessReviewSuccessCallback,
      failureCallback: getGBusinessReviewFailureCallback,
    };
    HttpServices.Get(getProps);
  };

  const handleClickComment = (comment) => {
    if (comment.reviewReply) return;
    setChildComment('');
    setChildCommentId(comment.reviewId);
  };

  const handleClickEditComment = (comment) => {
    setChildComment(comment.reviewReply.comment);
    setChildCommentId(comment.reviewId);
  };

  const handlePostComment = (comnt, isParent) => {
    setChildComment(comnt);
    setSendLoading(true);
    let postCommentBody = {
      connected_profile_id: gBusinessId,
      review_id: childCommentId,
      message: comnt,
    };
    let postProps = {
      url: postsModuleURL.addReviews,
      body: postCommentBody,
      successCallback: addReviewSuccessCallback,
      failureCallback: addReviewFailureCallback,
    };
    HttpServices.Post(postProps);
  };

  const handleDeleteReview = (id) => {
    setSendLoading(true);
    let postCommentBody = {
      connected_profile_id: gBusinessId,
      review_id: id,
    };
    let postProps = {
      url: postsModuleURL.deleteReviews,
      body: postCommentBody,
      successCallback: deleteReviewSuccessCallback,
      failureCallback: addReviewFailureCallback,
    };
    const updatedReviews = reviews.map((review) => {
      if (review.reviewId === id) {
        const { reviewReply, ...rest } = review;
        return rest;
      }
      return review;
    });
    setReviews(updatedReviews);
    HttpServices.Post(postProps);
  };

  const handleChangeComment = (e) => {
    setChildComment(e.target.value);
  };
  const RatingValue = (value) => {
    let rate = 0;
    switch (value) {
      case 'FIVE':
        rate = 5;
        break;
      case 'FOUR':
        rate = 4;
        break;
      case 'THREE':
        rate = 3;
        break;
      case 'TWO':
        rate = 2;
        break;
      case 'ONE':
        rate = 1;
        break;
      default:
        break;
    }
    return rate;
  };

  const commentView = (review) => {
    return (
      <Stack key={review.reviewId} direction="row" spacing={2} style={{ marginLeft: 15, marginRight: 15 }}>
        <Avatar alt={review.reviewer.displayName} src={review.reviewer.profilePhotoUrl} />
        <Paper sx={{ p: 1.5, flexGrow: 1, bgcolor: 'background.neutral', margin: '10px', boxShadow: '3' }}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems={{ sm: 'center' }}
            justifyContent="space-between"
            sx={{ mb: 0.5 }}
          >
            <Typography variant="subtitle2">{review.reviewer.displayName}</Typography>
            <Typography variant="caption" sx={{ color: 'text.disabled' }}>
              {CommonHelper.DateTimeFromCurrentTime(review.createTime)}
            </Typography>
          </Stack>
          <Rating name="read-only" value={RatingValue(review.starRating)} readOnly />
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {review.comment}
          </Typography>
          <Stack
            flexWrap="wrap"
            direction="row"
            justifyContent="flex-end"
            spacing={1}
            sx={{
              mt: 3,
              color: 'text.disabled',
            }}
          >
            <IconButton onClick={() => handleClickComment(review)}>
              <MessageOutlinedIcon
                sx={{ width: 20, height: 20, mr: 0.5, color: theme.palette.preview.comments.facebook }}
              />
            </IconButton>
          </Stack>
        </Paper>
      </Stack>
    );
  };

  const commentReplyView = (review) => {
    return (
      <Stack key={review.reviewId} direction="row" spacing={2} style={{ marginLeft: 15, marginRight: 15 }}>
        <Avatar />
        <Paper sx={{ p: 1.5, flexGrow: 1, bgcolor: 'background.neutral', margin: '10px', boxShadow: '3' }}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems={{ sm: 'center' }}
            justifyContent="space-between"
            sx={{ mb: 0.5 }}
          >
            <Typography variant="subtitle2">Author</Typography>
            <Typography variant="caption" sx={{ color: 'text.disabled' }}>
              {CommonHelper.DateTimeFromCurrentTime(review.reviewReply.updateTime)}
            </Typography>
          </Stack>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {review.reviewReply.comment}
          </Typography>
          <Stack
            flexWrap="wrap"
            direction="row"
            justifyContent="flex-end"
            spacing={1}
            sx={{
              mt: 3,
              color: 'text.disabled',
            }}
          >
            <IconButton onClick={() => handleClickEditComment(review)}>
              <EditIcon sx={{ width: 20, height: 20, mr: 0.5, color: theme.palette.primary.main }} />
            </IconButton>
            <IconButton onClick={() => handleDeleteReview(review.reviewId)}>
              <img src={DeleteCommentIcon} style={{ width: 20, height: 20 }} />
            </IconButton>
          </Stack>
        </Paper>
      </Stack>
    );
  };

  const writeContentView = (id, comment, isParent = false) => {
    return (
      <Card sx={{ boxShadow: 'none', marginLeft: isParent ? '0px' : '80px !important' }}>
        <Stack direction="row" alignItems="center" sx={{mt:'10px'}}>
          <TextField
            fullWidth
            size="small"
            value={comment}
            placeholder="Write a comment…"
            onChange={(e) => {
              handleChangeComment(e, isParent);
            }}
            sx={{
              ml: 2,
              mr: 1,
              '& fieldset': {
                borderWidth: `1px !important`,
                borderColor: (theme) => `${theme.palette.grey[500_32]} !important`,
              },
            }}
          />
          <LoadingButton
            variant="outlined"
            loading={isSendLoading}
            endIcon={<SendIcon />}
            size=""
            onClick={() => {
              handlePostComment(comment, isParent);
            }}
            disabled={!comment}
            sx={{
              border: 'none',
              '&.Mui-disabled': {
                border: 'none',
                opacity: 0.6,
              },
              '&.MuiLoadingButton-root:hover': {
                border: 'none',
                background: 'none',
              },
            }}
          ></LoadingButton>
          <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
        </Stack>
      </Card>
    );
  };

  const getGridView = () => {
    return (
      <Grid sx={{ maxHeight: 'calc(100vh - 360px)', overflow: 'auto' }}>
        <Stack>
          {reviews &&
            reviews.length > 0 &&
            reviews.map((review) => (
              <>
                <Card sx={{ m: '10px !important', boxShadow: 'none' }}>{commentView(review)}</Card>
                {review.reviewReply && (
                  <Card sx={{ marginLeft: '80px !important', boxShadow: 'none' }}>{commentReplyView(review)}</Card>
                )}
                {childCommentId && review.reviewId === childCommentId && writeContentView(childCommentId, childComment)}
              </>
            ))}
          {pagination !== '' && (
            <Box sx={{ textAlign: 'center', marginRight: '12rem !important' }}>
              {loaderReviews ? (
                <ThreeDotLoader />
              ) : (
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 700,
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: theme.palette.grey[500],
                  }}
                  onClick={getNextGBusinessReview}
                >
                  Load More Reviews
                </Typography>
              )}
            </Box>
          )}
        </Stack>
      </Grid>
    );
  };

  return (
    <Box>
      <>
        {isLoading ? (
          <Spinner height={height} />
        ) : reviews?.length > 0 ? (
          getGridView()
        ) : (
          <Box
            sx={{
              mb: 2,
              p: 2,
              maxHeight: 'calc(100vh - 360px)',
              minHeight: 'calc(100vh - 360px)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={NoMessage}
              style={{ width: '25%', height: '25%', maxWidth: '25%', maxHeight: '25%' }}
              alt="No Message Available"
            />
            <Typography variant="h4" sx={{ fontWeight: 800 }}>
              No Reviews Available
            </Typography>
          </Box>
        )}
      </>
    </Box>
  );
};
export default ReviewsGrid;
