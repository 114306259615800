import { ListItemText, Tooltip, styled, tooltipClasses } from '@mui/material';
import axios from 'axios';
import { format, formatDistanceToNowStrict, parse } from 'date-fns';
import moment from 'moment/moment';
import { Constants } from 'src/constants/stringConstants';
import { NewPostStyledComponent } from 'src/pages/new-post-new/newPostStyled.component';
import { PostUtil } from 'src/pages/new-post-new/post-util';
import HttpServices from 'src/services/httpService';
import { imageModeuleUrl, postsModuleURL, userModuleURL } from 'src/services/urlService';
import FacebookIcon from '../../src/assets/facebook.svg';
import GoogleMyBusinessIcon from '../../src/assets/google-my-business-round-logo.svg';
import InstagramIcon from '../../src/assets/instagram.svg';
import LinkedInIcon from '../../src/assets/linked_in.svg';
import AppLogo from '../../src/assets/logo.png';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import TikTokIcon from '../../src/assets/tiktok.svg';
import TwitterIcon from '../../src/assets/twitter.svg';
import YoutubeIcon from '../../src/assets/youtube.png';
import PinterestIcon from '../assets/pinterest-icon.svg'
import { LocalStorage } from './storage/local-storage';
import { SessionStorage } from './storage/session-storage';

const DateFormat = (date) => {
  return format(new Date(date), 'dd MMMM yyyy');
};
const CreateFileFromImageUrl = async (imageUrl, fileName) => {
  return axios
    .get(imageUrl, {
      responseType: 'arraybuffer',
    })
    .then((response) => {
      return new File([response.data], fileName, { type: response.headers['content-type'] });
    })
    .catch((error) => {
      return null;
    });
};

const GetLoggedInUserDetailsFromLocalStorage = () => {
  let user = null;
  let userDetails = LocalStorage.getItem('user_details');
  let userInfo = userDetails && JSON.parse(userDetails);
  if (userInfo) user = userInfo;
  return user;
};

const CheckUserLoggedIn = () => {
  let token = LocalStorage.getItem('token');
  return token ? true : false;
}

const GetCurrentFromLocalStorage = () => {
  let planDetails = null;
  let currentPlan = LocalStorage.getItem('current_plan');
  let plan = currentPlan && JSON.parse(currentPlan);
  if (plan) planDetails = plan;
  return planDetails;
};

const Logout = () => {
  localStorage.clear();
  sessionStorage.clear();
  const successCallback = (data, message) => {
    // ClearLocalStorage();
  };
  let postProps = {
    url: userModuleURL.logout,
    successCallback,
    failureCallback: successCallback
  };
  HttpServices.Get(postProps);
  window.location.href = '/login';
};

const GetCurrentPlan = () => {
  const planSuccessCallback = (data, message) => {
    LocalStorage.setItem('current_plan', JSON.stringify(data));
  };
  let getProps = {
    url: userModuleURL.getCurrentPlan,
    successCallback: planSuccessCallback,
  };
  HttpServices.Get(getProps);
};

const ServiceList = [
  { name: 'FACEBOOK', icon: FacebookIcon },
  { name: 'INSTAGRAM', icon: InstagramIcon },
  { name: 'TWITTER', icon: TwitterIcon },
  { name: 'YOUTUBE', icon: YoutubeIcon },
  { name: 'LINKEDIN', icon: LinkedInIcon },
  { name: 'TIKTOK', icon: TikTokIcon },
];

const PermissionList = {
  ORG_ADMIN: "is_org_admin",
  APPROVER: "is_approver",
  READ_POST: "published_posts",
  ALL_CHANNEL_ACCESS: "has_all_channel_access",
  PUBLISH_PERMISSION: "publish_permission",
  BRAND_ADMIN: "is_brand_admin",
  INTEGRATE_PERMISSION: "integrate_permission",
  MANAGE_USER_ACCOUNTS: "manage_user_accounts",
  PUBLISHED_POSTS: "published_posts",
  MANAGE_SOCIAL_INBOX: "manage_social_inbox",
  MANAGE_COMMENTS_LIKES: "manage_comments_likes",

};

const GetLocalTimefromUTC = (timeString) => {
  return moment.utc(timeString).local();
};

const GetBrandTimeFromUTC = (timeString) => {
  return moment.utc(timeString).local();
};

const GetDateFormat = (date) => {
  let dateString = '';
  let vDate = new Date(date).toString();
  if (vDate !== 'Invalid Date') dateString = moment.utc(new Date(date)).local().format('LLL').toString();
  return dateString;
};
const GetLinkedinPreviewDateFormat = (date) => {
  let cleanedDateString = date.replace("IST", "").trim() + "Z";
  let formattedDate = moment.utc(cleanedDateString).local().format('LLL');
  return formattedDate;
};


const GetLinkedinDateFormat = (dateString) => {
  let cleanedDateString = dateString.replace("IST", "").trim() + "Z";
  let date = new Date(cleanedDateString);
  return getTableDateAndTime(date);
};
const formatTime = (time) => {
  time = checkForInValidDate(time) ? time : new Date();
  return format(new Date(time), 'h:mm a');
};
const checkForInValidDate = (date) => {
  if (date && new Date(date).toString() !== 'Invalid Date') return true;
  else return false;
};


const getTableDateAndTime = (date) => {
  return <ListItemText
    sx={{
      m: 0,
      '.MuiListItemText-primary': {
        // Define styles from .MuiListItemText-primary here
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontSize: '0.875rem',
      },
      '.MuiListItemText-secondary': {
        // Define styles from .MuiListItemText-primary here
        color: '#637381',
        // margin: '4px 0px 0px',
        fontSize: '0.75rem',
        textTransform: 'lowercase',
      }
    }}
    primary={formatDateAndTime(date, "date")}
    secondary={formatDateAndTime(date, "time")}>
  </ListItemText>
}
const formatDateAndTime = (date, type = "") => {
  let formatedDateAndTime = null;
  let formatedDate = formateDateWithYear(date);
  let time = formatTime(date);
  if (type === "date")
    formatedDateAndTime = formatedDate;
  else if (type === "time")
    formatedDateAndTime = time;
  else
    formatedDateAndTime = formatedDate + " " + time;
  return formatedDateAndTime;
}
const formateDateWithYear = (date) => {
  date = checkForInValidDate(date) ? date : new Date();
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const monthIndex = new Date(date).getMonth();
  const monthName = monthNames[monthIndex];
  return format(new Date(date), `dd MMMM yyyy`).replace(monthName, monthName.slice(0, 3));
};
const CalculateAspectRatio = (width, height) => {
  const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
  const aspectRatio = `${width / gcd(width, height)}:${height / gcd(width, height)}`;
  return aspectRatio;
};
const BytesToGB = (bytes) => {
  return (bytes / (1024 * 1024 * 1024)).toFixed(2);
};

const DateTimeFromCurrentTime = (dateTime) => {
  if (dateTime == undefined || dateTime == "") {
    return null;
  }
  return formatDistanceToNowStrict(new Date(dateTime), {
    addSuffix: true,
  });
};

const LinkedinTimeFromCurrentTime = (dateTime) => {
  const parsedDate = parse(dateTime, "yyyy-MM-dd HH:mm:ss 'IST'", new Date());
  return formatDistanceToNowStrict(parsedDate, {
    addSuffix: true,
  });
};

const GetFileTypeFromUrl = (url) => {
  const parts = url.split('.');
  const fileExtension = parts[parts.length - 1];
  const lowercaseExtension = fileExtension.toLowerCase();
  return lowercaseExtension;
};
const GetUniqueValueFromArray = (values) => {
  return [...new Set(values.map((item) => (typeof item === 'number' ? item : item.trim())))];
};
const GetAllBrandDetials = () => {
  let brandDetails = "";
  let brandJson = LocalStorage.getItem("brand_details");
  if (brandJson)
    brandDetails = JSON.parse(brandJson);
  return brandDetails ? brandDetails : "";
}

const SetAllBrandDetials = () => {
  const getCurrentBrandDetials = (data, message) => {
    LocalStorage.setItem('brand_details', JSON.stringify(data));
  }
  let postProps = {
    url: userModuleURL.fetchUsersBrand,
    successCallback: getCurrentBrandDetials
  };
  HttpServices.Get(postProps);
}
const CommonNewPostStruct = (id, newPostSucessCallback, failureCallback) => {
  let getProps = {
    url: `${postsModuleURL.scheduledEventInfo}${id}`,
    successCallback: newPostSucessCallback,
    failureCallback: failureCallback
  };
  HttpServices.Get(getProps);
}

const CommonNewPostAddMediaAllService = (newPostForm, mediaFiles) => {
  let newFormData = { ...newPostForm };
  newFormData.media_url = mediaFiles.map((a) => a.media_url).join();
  newFormData.media_data = mediaFiles;
  let facebookRequestData = newFormData.scheduled_event_requests_data.facebook_requests_data;
  let instagramRequestData = newFormData.scheduled_event_requests_data.instagram_requests_data;
  let youtubeRequestData = newFormData.scheduled_event_requests_data.youtube_requests_data;
  let twitterRequestData = newFormData.scheduled_event_requests_data.twitter_requests_data;
  let linkedinRequestData = newFormData.scheduled_event_requests_data.linkedin_requests_data;
  let gbusinessRequestData = newFormData.scheduled_event_requests_data.gbusiness_requests_data;
  let tiktokRequestData = newFormData.scheduled_event_requests_data.tiktok_requests_data;
  let pinterestRequestData = newFormData.scheduled_event_requests_data.pinterest_requests_data;
  if (facebookRequestData?.length > 0) {
    facebookRequestData.map((c) => {
      c.media_url = newFormData.media_url;
      c.media_data = mediaFiles;
    });
  }
  if (instagramRequestData?.length > 0) {
    instagramRequestData.map((c) => {
      c.media_url = newFormData.media_url;
      c.media_data = mediaFiles;
    });
  }
  if (youtubeRequestData?.length > 0) {
    youtubeRequestData.map((c) => {
      c.media_url = newFormData.media_url;
      c.media_data = mediaFiles;
    });
  }
  if (twitterRequestData?.length > 0) {
    twitterRequestData.map((c) => {
      c.media_url = newFormData.media_url;
      c.media_data = mediaFiles;
    });
  }
  if (linkedinRequestData?.length > 0) {
    linkedinRequestData.map((c) => {
      c.media_url = newFormData.media_url;
      c.media_data = mediaFiles;
    });
  }
  if (gbusinessRequestData?.length > 0) {
    gbusinessRequestData.map((c) => {
      c.media_url = newFormData.media_url;
      c.media_data = mediaFiles;
    });
  }
  if (tiktokRequestData?.length > 0) {
    tiktokRequestData.map((c) => {
      c.media_url = newFormData.media_url;
      c.media_data = mediaFiles;
    });
  }
  if (pinterestRequestData?.length > 0) {
    pinterestRequestData.map((c) => {
      c.media_url = newFormData.media_url;
      c.media_data = mediaFiles;
    });
  }
  return newFormData;
}

const DeviceType = {
  Mobile: 'Mobile',
  Desktop: 'Desktop',
};
const GetSocialMediaAvatar = (service_name, isChild = false) => {
  let sourceFile = null;
  switch (service_name.toUpperCase().trim()) {
    case PostUtil.ServiceName.TWITTER:
      sourceFile = TwitterIcon;
      break;
    case PostUtil.ServiceName.FACEBOOK:
      sourceFile = FacebookIcon;
      break;
    case PostUtil.ServiceName.YOUTUBE:
      sourceFile = YoutubeIcon;
      break;
    case PostUtil.ServiceName.INSTAGRAM:
      sourceFile = InstagramIcon;
      break;
    case PostUtil.ServiceName.TIKTOK:
      sourceFile = TikTokIcon;
      break;
    case PostUtil.ServiceName.LINKEDIN:
      sourceFile = LinkedInIcon;
      break;
    case PostUtil.ServiceName.GBUSINESS:
      sourceFile = GoogleMyBusinessIcon;
      break;
    case PostUtil.ServiceName.PINTEREST:
      sourceFile = PinterestIcon;
      break;
    default:
      break;
  }
  return isChild ? <NewPostStyledComponent.SmallAvatarChild alt={service_name} src={sourceFile} /> :
    <NewPostStyledComponent.SmallAvatar alt={service_name} src={sourceFile} />;
}
const AppInitCall = (planSuccessCallback, initCallFailureCallback) => {
  let getProps = {
    url: userModuleURL.intialLoad,
    successCallback: planSuccessCallback,
    failureCallback: initCallFailureCallback
  };
  HttpServices.Get(getProps);
};
const CheckForPermissionAvailableForUser = (permission = PermissionList.ORG_ADMIN) => {
  let isHavePermission = false;
  let initialLoad = LocalStorage.getItem("initial-load");
  if (initialLoad) {
    let data = JSON.parse(initialLoad);
    if (data) {
      const activeBrandId = CommonHelper.GetCurrentBrandId();
      let brandDetails = data.brand_details.find(a => a.brand_id === activeBrandId);
      if (brandDetails?.permission_details) {
        let permissions = brandDetails.permission_details;
        if (permissions) {
          isHavePermission = permissions[permission];
        }
      }
    }
  }
  return isHavePermission;
}
const GetUserIsVerified = () => {

  let isVerified = false;
  let initialLoad = LocalStorage.getItem("initial-load");
  if (initialLoad) {
    let iLoad = JSON.parse(initialLoad);
    if (iLoad) isVerified = iLoad.is_verified;
  }
  return isVerified
}
const GetAppLogo = () => {
  let logo = AppLogo;
  let appDetails = GetAppDetails();
  if (appDetails) {
    let aDetails = appDetails;
    if (aDetails) {
      logo = aDetails.small_icon ? aDetails.small_icon : logo;
    }
  }
  return logo;
}
const GetAppName = () => {
  let appName = "Brand2Social";
  let appDetails = LocalStorage.getItem("app-details");
  if (appDetails) {
    let aDetails = JSON.parse(appDetails);
    if (aDetails)
      appName = aDetails.org_name;
  }
  return appName;
}

const GetBrandTimeZone = () => {
  let timeZone = 'Asia/Kolkata';
  let brandTimeZone = LocalStorage.getItem('timezone');
  if (brandTimeZone) {
    timeZone = JSON.parse(brandTimeZone);
  }
  return timeZone;
}

const GetAppDetails = () => {
  let appDetails = null;
  let appDetailsJson = LocalStorage.getItem('app_details');
  if (appDetailsJson) {
    appDetails = JSON.parse(appDetailsJson);
  }
  return appDetails;
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    PopperProps={{
      modifiers: [
        {
          name: 'flip',
          enabled: true,
          options: {
            fallbackPlacements: ['right'],
          },
        },
      ],
    }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'transparent',
    color: 'inherit',
    fontSize: theme.typography.pxToRem(12),
    border: 'none',
    padding: 0,
    maxWidth: 'none',
  },
  [`& .${tooltipClasses.popper}`]: {
    padding: 0,
    margin: 0,
  }
}));

const ReArrangeArray = (arr, oldIndex, newIndex) => {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};
const GetFileFromUrl = async (url, filename, mimeType) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], filename, { type: mimeType });
};
const UploadMediaFilesToServer = ({ files, setFileUploadprogress, successCallback, failureCallback }) => {
  let formData = new FormData();
  for (var i = 0; i < files.length; i++) {
    formData.append('fileupload', files[i]);
  }
  let mediaProps = {
    formData,
    setFileUploadprogress,
    successCallback,
    failureCallback
  };
  HttpServices.UploadMediaFiles(mediaProps);
}

const UploadPrivateMediaFilesToServer = ({ privateFiles, successCallback, failureCallback }) => {
  const mediaurls = privateFiles.map(c => c.media_url)
  let mediaBody = {
    media_urls: mediaurls
  };
  let postProps = {
    url: imageModeuleUrl.fetchPrivateMediaMetadata,
    body: mediaBody,
    successCallback,
    failureCallback
  }
  HttpServices.Post(postProps);
}

const GetCurrentTimeWithBrandTimeZone = () => {
  const timeZone = GetBrandTimeZone();
  const options = { timeZone, year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
  const formatter = new Intl.DateTimeFormat([], options);
  return formatter.format(new Date());
}

const ChangeTimeToBrandTime = () => {

}

const ProcessMediaUrl = (mediaStr = "") => {
  if (mediaStr === "") {
    return {
      count: 0,
      media_data: []
    }
  }
  const mediaUrl = mediaStr.split(",");

  const media_data = mediaUrl.map(url => ({
    url: url,
    type: GetMediaType(url)
  }));
  return {
    count: mediaUrl.length,
    media_data: media_data
  }
}

function GetMediaType(url = '') {
  const lowercasedUrl = url.toLowerCase();
  if (lowercasedUrl.endsWith('.jpeg') || lowercasedUrl.endsWith('.jpg') || lowercasedUrl.endsWith('.png')) {
    return PostUtil.MediaType.IMAGE;
  }
  if (lowercasedUrl.endsWith('.mp4')) {
    return PostUtil.MediaType.VIDEO;
  }
  if (lowercasedUrl.endsWith('.pdf')) {
    return PostUtil.MediaType.DOCUMENT;
  }
  return "";
}
const PublishedPostProcessMediaUrl = (mediaStr = "") => {
  if (mediaStr === "") {
    return {
      count: 0,
      media_data: []
    }
  }
  const mediaUrl = mediaStr.split(",");

  const media_data = mediaUrl.map(url => ({
    url: url,
    type: GetPublishedPostMediaType(url)
  }));
  return {
    count: mediaUrl.length,
    media_data: media_data
  }
}

function GetPublishedPostMediaType(url = '') {
  const lowercasedUrl = url.toLowerCase();
  if (lowercasedUrl.endsWith('.jpeg') || lowercasedUrl.endsWith('.jpg') || lowercasedUrl.includes('jpg')
    || lowercasedUrl.endsWith('.png') || lowercasedUrl.includes('image')) {
    return PostUtil.MediaType.IMAGE;
  }
  if (lowercasedUrl.endsWith('.mp4') || lowercasedUrl.includes('mp4')) {
    return PostUtil.MediaType.VIDEO;
  }
  if (lowercasedUrl.endsWith('.pdf') || lowercasedUrl.includes('pdf')) {
    return PostUtil.MediaType.DOCUMENT;
  }
  return "";
}


const PreviewGroupBy = (array, key) => {
  let resp = array.reduce((result, currentValue) => {
    const keyValue = currentValue[key];

    if (!result[keyValue]) {
      result[keyValue] = {
        data: [],
        connected_profile_details: currentValue.connected_profile_details,
      };
    }

    result[keyValue].data.push(currentValue);

    return result;
  }, {});

  return resp
}

const DisplayServiceName = (name) => {
  switch (name) {
    case 'facebook':
      return 'FACEBOOK';
    case 'instagram':
      return 'INSTAGRAM';
    case 'youtube':
      return 'YOUTUBE';
    case 'twitter':
      return 'TWITTER'
    case 'linkedin':
      return 'LINKEDIN'
    case 'gbusiness':
      return 'GOOGLE MY BUSINESS';
    case 'tiktok':
      return 'TIKTOK';
    case 'pinterest':
      return 'PINTEREST';
    default:
      return '';
  }
}
const DisplayPlanAddonName = (name) => {
  switch (name) {
    case 'no_of_social_accounts':
      return 'Social Account';
    case 'no_of_users':
      return 'Users';
    case 'no_of_brands':
      return 'Brands';
    case 'no_of_scheduled_posts':
      return 'Scheduled Posts'
    case 'no_of_storage':
      return 'Storage'
    case 'no_of_drafts':
      return 'Drafts'
    default:
      return '';
  }
}
const GetSeviceId = (service_name) => {
  let id = 0;
  switch (service_name.toUpperCase().trim()) {
    case PostUtil.ServiceName.TWITTER:
      id = 3;
      break;
    case PostUtil.ServiceName.FACEBOOK:
      id = 1;
      break;
    case PostUtil.ServiceName.YOUTUBE:
      id = 4;
      break;
    case PostUtil.ServiceName.INSTAGRAM:
      id = 2;
      break;
    case PostUtil.ServiceName.TIKTOK:
      id = 6;
      break;
    case PostUtil.ServiceName.LINKEDIN:
      id = 5;
      break;
    case PostUtil.ServiceName.GBUSINESS:
      id = 7;
      break;
    default:
      break;
  }
  return id;
}

const B2SGroupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    const keyValue = currentValue[key];

    if (!result[keyValue]) {
      result[keyValue] = [];
    }

    result[keyValue].push(currentValue);

    return result;
  }, {});
}
const CheckAspectRatioForVideo = (media_data, aspectRatio) => {
  let errorMessage = "";
  let isVideoFileAvailable = media_data.some(a => a.media_type === "VIDEO");
  if (isVideoFileAvailable) {
    for (var i = 0; i < media_data.length; i++) {
      let mediaFile = media_data[i];
      if (mediaFile.media_type === "VIDEO") {
        let selectedVideosAspectRatio = CalculateAspectRatio(mediaFile.aspect_ratio.width, mediaFile.aspect_ratio.height);
        if (!(aspectRatio === selectedVideosAspectRatio))
          errorMessage = Constants.NewPost.AspectRatioErrorMessage;
      }
    }
  }
  return errorMessage;
}
const TikTokCheckAspectRatioForVideoAndImage = (media_data, aspectRatio) => {
  let errorMessage = "";
  let isVideoFileAvailable = media_data.some(a => a.media_type === "IMAGE" || a.media_type === "VIDEO");
  if (isVideoFileAvailable) {
    for (var i = 0; i < media_data.length; i++) {
      let mediaFile = media_data[i];
      if (mediaFile.media_type === "IMAGE" || mediaFile.media_type === "VIDEO") {
        let selectedVideosAspectRatio = CalculateAspectRatio(mediaFile.aspect_ratio.width, mediaFile.aspect_ratio.height);
        let isAspectRatioValid = aspectRatio.includes(selectedVideosAspectRatio)
        if (!isAspectRatioValid)
          errorMessage = Constants.NewPost.AspectRatioErrorMessage;
      }
    }
  }
  return errorMessage;
}

const GetBrandInfoFromLocalStorage = (type = "initial-load") => {
  let details = null;
  let initialLoad = LocalStorage.getItem("initial-load");

  if (initialLoad) {
    let data = JSON.parse(initialLoad);

    if (data) {
      switch (type) {
        case "is_user_enabled":
          details = data.is_user_enabled;
          break;

        case "current_plan_info":
          details = data.current_plan_info;
          break;

        case "is_appsumo_user":
          details = data.current_plan_info?.is_appsumo_user;
          break;

        case "org_id":
          details = data.org_id;
          break;

        case "org_name":
          details = data.org_name;
          break;

        case "org_description":
          details = data.org_description;
          break;

        case "org_icon_url":
          details = data.org_icon_url;
          break;

        case "is_org_user":
          details = data.is_org_user;
          break;

        case "domain_color":
          details = {
            domain_primary_color: data.domain_primary_color || CommonHelper.DomainColor.Primary,
            domain_secondary_color: data.domain_secondary_color || CommonHelper.DomainColor.Secondary,
          };
          break;

        case "permissions":
          const activeBrandId = CommonHelper.GetCurrentBrandId();
          if (activeBrandId) {
            let brandDetails = data.brand_details.find(a => a.brand_id === activeBrandId);
            if (brandDetails) {
              details = brandDetails.permission_details;
            }
          }
          break;

        case "brand_details":
          details = data.brand_details;
          break;

        case "user_details":
          details = data.user_details;
          break;

        case "initial-load":
        default:
          details = data;
          break;
      }
    }
  }

  return details;
}
const AppLoginCall = (data, isNavigateToDefault = false) => {
  LocalStorage.setItem('token', data.token);
  if (data?.is_register) {
    window.location.href = '/welcome';
    return
  }
  if (isNavigateToDefault) {
    window.location.href = window.location.origin;
  }
  else {
    window.location.reload();
  }
}
const LeaveOrganization = (successCallback, failureCallback) => {
  let postProps = {
    url: userModuleURL.leaveOrganization,
    successCallback,
    failureCallback,
  };
  HttpServices.Post(postProps);
}
const DeleteOrganization = (successCallback, failureCallback) => {
  let postProps = {
    url: userModuleURL.deleteOrg,
    successCallback,
    failureCallback,
  };
  HttpServices.Post(postProps);
}
const GetCurrentBrandId = () => {
  let currentBrandId = 0;
  let currentBrandIdJson = SessionStorage.getItem("current_brand_id");
  if (currentBrandIdJson) {
    let brandId = JSON.parse(currentBrandIdJson);
    if (brandId) {
      currentBrandId = brandId;
    }
  }
  return currentBrandId;
}
const ClearAppLocalStorage = () => {
  let token = LocalStorage.getItem("token");
  let show = LocalStorage.getItem("showPlanDetailsPopupOnce");
  let publicUserName = LocalStorage.getItem("publicUserName");
  localStorage.clear();
  if (token) {
    LocalStorage.setItem("token", token);
    LocalStorage.setItem("showPlanDetailsPopupOnce", show);
  }
  if (publicUserName) {
    LocalStorage.setItem("publicUserName", publicUserName);
  }
}
const CheckAndUpdateApiCallTime = (url) => {
  if (!url?.includes(userModuleURL.fetchNotification)) {
    let currentDateTime = new Date();
    LocalStorage.setItem("LastApiCall", JSON.stringify(currentDateTime));
  }
}
const GetLastApiCallTime = () => {
  let lastApiCallTime = null;
  let lastApiCallJson = LocalStorage.getItem("LastApiCall");
  if (lastApiCallJson) {
    lastApiCallTime = JSON.parse(lastApiCallJson);
  }
  return lastApiCallTime;
}

const DefaultPermissionRoute = "/published-post";
const DomainColor = {
  Primary: "#00AB55",
  Secondary: "#C8FAD6CC"
}

const DetermineCurrency = (timeZone) => {
  const normalizedTimeZone = timeZone === 'Asia/Calcutta' ? 'Asia/Kolkata' : timeZone;
  const timeZoneCurrencyMap = {
    'Asia/Kolkata': 'INR',
    'America/New_York': 'USD',
    'Europe/London': 'GBP',
  };

  const detectedCurrency = timeZoneCurrencyMap[normalizedTimeZone] || 'USD';
  return detectedCurrency
};
const formatDate = (date) => {
  return date.toLocaleDateString("en-US", {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
  });
};

const DownloadAsPDF = (id,startDate,endDate,handlePopoverClose) => {
  const applicationName = "Brand2Social"; // Replace with your application name
  const username = "feedthe.needs"; // Replace with the actual username
  const reportDate = formatDate(new Date());
  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  const component = document.getElementById(id);
  html2canvas(component).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();

      // Draw top line
      pdf.setLineWidth(0.5);
      pdf.line(10, 20, 200, 20); // Top line slightly lower for spacing

      // Header elements
      pdf.setFontSize(18); // Larger size for username
      pdf.text(username, 10, 12); // Adjusted y coordinate for the username
      pdf.setFontSize(10);
      pdf.setTextColor(150);
      pdf.text(`${formattedStartDate} to ${formattedEndDate}`, 10, 18); // Updated to show "to" instead of "on"
      pdf.addImage(AppLogo, 'PNG', 185, 5, 8, 8); // App icon aligned to the right

      pdf.setFontSize(8);
      pdf.text(`Generated by ${applicationName} on ${reportDate}`, 130, 18);

      pdf.addImage(imgData, 'PNG', 10, 25, 180, 0);
      const contentHeight = 25 + (canvas.height * 180) / canvas.width;

      pdf.line(10, contentHeight + 10, 200, contentHeight + 10);
      pdf.setFontSize(10);

      pdf.save(`${id}.pdf`);
  });
  handlePopoverClose();
};

const DownloadAsPNG = (id,startDate,endDate,handlePopoverClose) => {
  const applicationName = "Brand2Social"; 
  const username = "feedthe.needs"; 
  const reportDate = formatDate(new Date());
  const padding = 20; // Define your overall padding here
  const logoSize = 32; // Set the new size for the logo
  const logoOffset = 5; // Set the offset for the logo to move it up

  const component = document.getElementById(id);

  html2canvas(component).then((canvas) => {
      // Create a new canvas to draw the complete image
      const combinedCanvas = document.createElement('canvas');
      const ctx = combinedCanvas.getContext('2d');

      // Set the width and height of the combined canvas
      combinedCanvas.width = canvas.width + padding * 2; // Add padding for both sides
      combinedCanvas.height = canvas.height + 120 + padding; // Additional height for header and line + padding

      // Draw the original canvas with padding
      ctx.drawImage(canvas, padding, 90 + padding); // Offset by padding for left and adjusted top for header padding

      // Draw header elements on the new canvas
      ctx.font = 'bold 20px Helvetica'; // Font for username
      ctx.fillStyle = 'black'; // Set color for username to black
      ctx.fillText(username, padding, 30 + padding); // Username with padding

      ctx.font = 'bold 12px Helvetica'; // Font for reporting date
      ctx.fillStyle = '#A9A9A9'; // Light grey color for the subtitle
      ctx.fillText(`${formatDate(startDate)} to ${formatDate(endDate)}`, padding, 50 + padding); // Date range with padding

      const logoImg = new Image();
      logoImg.src = AppLogo; // Use your AppLogo image source
      logoImg.onload = () => {
          // Draw the logo with the new size, moving it slightly upward
          ctx.drawImage(logoImg, combinedCanvas.width - logoSize - padding, 10 + padding - logoOffset, logoSize, logoSize); // App logo with padding and new size

          // Adjust the reporting date position
          const reportTextX = combinedCanvas.width - 300 - padding; // X position for reporting text
          const reportTextY = 50 + padding; // Y position for reporting text
          ctx.fillStyle = '#A9A9A9'; // Set color for reporting date to light grey
          ctx.fillText(`Generated by ${applicationName} on ${reportDate}`, reportTextX, reportTextY); // Add space between logo and reporting text

          // Draw a separator line
          ctx.beginPath();
          ctx.moveTo(padding, 60 + padding); // Start the line just below the header with padding
          ctx.lineTo(combinedCanvas.width - padding, 60 + padding); // End the line on the opposite side with padding
          ctx.lineWidth = 0.5; // Line thickness
          ctx.strokeStyle = 'black'; // Line color
          ctx.stroke(); // Draw the line

          // Prepare the image data for download
          const imgData = combinedCanvas.toDataURL('image/png');

          const link = document.createElement('a');
          link.href = imgData;
          link.download = `${id}.png`;
          link.click();
      };
  });
  handlePopoverClose(); // Close popover after action
};

export const CommonHelper = {
  ProcessMediaUrl,
  GetCurrentTimeWithBrandTimeZone,
  B2SGroupBy,
  PreviewGroupBy,
  CommonNewPostStruct,
  DisplayServiceName,
  HtmlTooltip,
  DateFormat,
  formatDateAndTime,
  formatTime,
  formateDateWithYear,
  CreateFileFromImageUrl,
  GetLoggedInUserDetailsFromLocalStorage,
  Logout,
  GetLocalTimefromUTC,
  GetBrandTimeFromUTC,
  GetDateFormat,
  DateTimeFromCurrentTime,
  GetCurrentPlan,
  GetCurrentFromLocalStorage,
  ServiceList,
  CalculateAspectRatio,
  BytesToGB,
  GetFileTypeFromUrl,
  GetUniqueValueFromArray,
  GetLinkedinDateFormat,
  GetAllBrandDetials,
  SetAllBrandDetials,
  DeviceType,
  PermissionList,
  GetSocialMediaAvatar,
  AppInitCall,
  CheckForPermissionAvailableForUser,
  GetAppLogo,
  GetAppName,
  GetBrandTimeZone,
  GetAppDetails,
  ReArrangeArray,
  GetFileFromUrl,
  UploadMediaFilesToServer,
  CheckUserLoggedIn,
  getTableDateAndTime,
  CheckAspectRatioForVideo,
  GetBrandInfoFromLocalStorage,
  DefaultPermissionRoute,
  PublishedPostProcessMediaUrl,
  AppLoginCall,
  CommonNewPostAddMediaAllService,
  UploadPrivateMediaFilesToServer,
  LeaveOrganization,
  DeleteOrganization,
  GetLinkedinPreviewDateFormat,
  GetUserIsVerified,
  GetMediaType,
  GetSeviceId,
  GetCurrentBrandId,
  DomainColor,
  GetAppDetails,
  ClearAppLocalStorage,
  DisplayPlanAddonName,
  TikTokCheckAspectRatioForVideoAndImage,
  CheckAndUpdateApiCallTime,
  GetLastApiCallTime,
  DisplayPlanAddonName,
  DetermineCurrency,
  DownloadAsPNG,
  DownloadAsPDF
};
