import { Navigate } from 'react-router-dom';
import PaymentRedirect from './components/stripe/paymentRedirect';
import PaymentResponse from './components/stripe/paymentResponse';
import StripeComponent from './components/stripe/planPaymentComponent';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import Register from './pages/Register';
import VerifyEmailComponent from './pages/VerifyEmail';
import BillingComponent from './pages/account/billing/billing.component';
import UserAccount from './pages/account/userAccount';
import AppSumoRegisteration from './pages/app-sumo/appSumoRegister.component';
import NoBrand from './pages/brand/noBrand.component';
import Calendar from './pages/calendar/calendar';
import ChangePassword from './pages/changePassword/changePassword';
import Chat from './pages/chat/Chat';
import Draft from './pages/draft/draftComponent';
import GoogleRedirect from './pages/google-signin/googleRedirect.component';
import FacebookRedirect from './pages/integration/facebookRedirect.component';
import IntegrationComponent from './pages/integration/integration.component';
import LinkedInRedirect from './pages/integration/linkedinIntegration/linkedInRedirect.component';
import TikTokRedirect from './pages/integration/tiktokIntegration/tiktokRedirect.component';
import TwitterRedirect from './pages/integration/twitterIntegration/twitterRedirect.component';
import YoutubeRedirect from './pages/integration/youtubeIntegration/youtubeRedirect.component';
import CreateUserFromToken from './pages/invite-users/createUserFromToken.component';
import ManageBrandInfo from './pages/manage-brand/manageBrandInfo.component';
import Approval from './components/approval/approval.component';
import PublicApproval from './components/public-approval/public-approval.component';
import NotificationSetting from './components/notifications/notification-settings.component';
import Branding from './pages/branding/branding.component';
import Dashboard from './pages/dashboard/dashboard.component';
import GoogleMyBusinessRedirect from './pages/integration/google-my-business-integration/google-my-business-redirect';
import ThreadsRedirect from './pages/integration/threads-integration/threads-redirect.component';
import MediaLibrary from './pages/media-library/media-library.component';
import NoPermissionComponent from './pages/no-permission.component';
import ManageOrganization from './pages/organization/manageOrganization.component';
import NoOrganization from './pages/organization/noOrganization.component';
import PaymentPlan from './pages/payment/payment.component';
import PostInfoComponent from './pages/posts/post-info.component';
import PublishedPostComponent from './pages/publishedPosts/publishedPosts.component';
import ResetPassword from './pages/resetPassword/resetPassword';
import WelcomePageComponent from './pages/welcome/welcomePage.component';
import { CommonHelper } from './utils/commonHelper';
import NewPostComponent from './pages/new-post-new/new-post.component';
import PrivateRouteComponent from './privateRoute';
import DisableUserComponent from './pages/user-disable/disableUser.component';
import BillingInfoComponent from './pages/account/billing/billing-info.component';
import PaymentFailureComponent from './components/stripe/payment-failure.component';
import PaymentSuccessComponent from './components/stripe/payment-success.component';
import PinterestRedirect from './pages/integration/pinterestIntegration/pinterestRedirect.component';
import Report from './pages/reports/report.component'
import ScheduledReport from './pages/scheduled-reports/scheduled-reports.component';

const PrivateRoute = () => {
  return [
    {
      path: 'new-post',
      element: (
        <PrivateRouteComponent
          permissions={`${CommonHelper.PermissionList.PUBLISH_PERMISSION}`}
        >
          <NewPostComponent />
        </PrivateRouteComponent>
      ),
    },
    {
      path: 'integration',
      element: (
        <PrivateRouteComponent
          permissions={`${CommonHelper.PermissionList.INTEGRATE_PERMISSION}`}
        >
          <IntegrationComponent />
        </PrivateRouteComponent>
      ),
    },
    {
      path: 'published-post',
      element: (
        <PrivateRouteComponent
          permissions={`${CommonHelper.PermissionList.READ_POST}`}
        >
          <PublishedPostComponent />
        </PrivateRouteComponent>
      ),
    },
    {
      path: 'report',
      element: (
        <PrivateRouteComponent
          permissions={`${CommonHelper.PermissionList.READ_POST}`}
        >
          <Report />
        </PrivateRouteComponent>
      ),
    },
    {
      path: 'upcoming-post',
      element: (
        <PrivateRouteComponent
          permissions={`${CommonHelper.PermissionList.PUBLISH_PERMISSION}`}
        >
          <PostInfoComponent />
        </PrivateRouteComponent>
      ),
    },
    {
      path: 'draft',
      element: (
        <PrivateRouteComponent
          permissions={`${CommonHelper.PermissionList.PUBLISH_PERMISSION}`}
        >
          <Draft />
        </PrivateRouteComponent>
      ),
    },
    {
      path: 'calendar',
      element: (
        <PrivateRouteComponent permissions={`${CommonHelper.PermissionList.PUBLISH_PERMISSION}`}>
          <Calendar />
        </PrivateRouteComponent>
      ),
    },
    {
      path: 'profile',
      element: (
        <PrivateRouteComponent
          permissions={`${CommonHelper.PermissionList.READ_POST}`}
        >
          <UserAccount />
        </PrivateRouteComponent>
      ),
    },
    {
      path: 'billing',
      element: (
        <PrivateRouteComponent permissions={`${CommonHelper.PermissionList.ORG_ADMIN}`}>
          <BillingInfoComponent />
        </PrivateRouteComponent>
      ),
    },
    {
      path: 'message',
      element: (
        <PrivateRouteComponent permissions={`${CommonHelper.PermissionList.MANAGE_SOCIAL_INBOX}`}>
          <Chat />
        </PrivateRouteComponent>
      ),
    },
    {
      path: 'manage-brand',
      element: (
        <PrivateRouteComponent
          permissions={`${CommonHelper.PermissionList.READ_POST}`}
        >
          <ManageBrandInfo />
        </PrivateRouteComponent>
      ),
    },
    {
      path: 'manage-organization',
      element: (
        <PrivateRouteComponent
          permissions={`${CommonHelper.PermissionList.READ_POST}`}
        >
          <ManageOrganization />
        </PrivateRouteComponent>
      ),
    },
    {
      path: 'branding',
      element: (
        <PrivateRouteComponent permissions={CommonHelper.PermissionList.ORG_ADMIN}>
          <Branding />
        </PrivateRouteComponent>
      ),
    },
    {
      path: 'media-library',
      element: (
        <PrivateRouteComponent
          permissions={`${CommonHelper.PermissionList.PUBLISH_PERMISSION}`}
        >
          <MediaLibrary />
        </PrivateRouteComponent>
      ),
    },
    {
      path: 'notification-settings',
      element: (
        <PrivateRouteComponent
          permissions={`${CommonHelper.PermissionList.READ_POST}`}
        >
          <NotificationSetting />
        </PrivateRouteComponent>
      ),
    },
    {
      path: 'approval',
      element: (
        <PrivateRouteComponent
          permissions={`${CommonHelper.PermissionList.READ_POST}`}
        >
          <Approval />
        </PrivateRouteComponent>
      ),
    },
    {
      path: 'dashboard',
      element: (
        <PrivateRouteComponent
          permissions={`${CommonHelper.PermissionList.READ_POST}`}
        >
          <Dashboard />
        </PrivateRouteComponent>
      ),
    },
    {
      path: 'stripe-payment',
      element: (
        <PrivateRouteComponent permissions={`${CommonHelper.PermissionList.ORG_ADMIN}`}>
          <StripeComponent />
        </PrivateRouteComponent>
      ),
    },
    {
      path: 'welcome',
      element: (
        <PrivateRouteComponent
          permissions={`${CommonHelper.PermissionList.READ_POST}`}
        >
          <WelcomePageComponent />
        </PrivateRouteComponent>
      ),
    }
  ]
}

const PublicRoutes = () => {
  return [
    {
      path: 'not-found',
      element: <Page404 />,
    },
    {
      path: 'no-permission',
      element: <NoPermissionComponent />,
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'reset-password',
      element: <ResetPassword />,
    },
    {
      path: 'change-password',
      element: <ChangePassword />,
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: 'coupon-register',
      element: <AppSumoRegisteration />,
    },
    {
      path: 'payment-response',
      element: <PaymentResponse />,
    },
    {
      path: '*',
      element: <Navigate to="/login" replace />,
    },
    {
      path: 'verify-user',
      element: <VerifyEmailComponent />,
    },
    {
      path: 'create-invite-user',
      element: <CreateUserFromToken />,
    },
    {
      path: 'no-brand',
      element: <NoBrand />,
    },
    {
      path: 'no-organization',
      element: <NoOrganization />,
    },
    {
      path: 'user-disable',
      element: <DisableUserComponent />,
    },
    // Redirect Routes
    {
      path: 'twitter-redirect',
      element: <TwitterRedirect />,
    },
    {
      path: 'facebook-redirect',
      element: <FacebookRedirect />,
    },
    {
      path: 'youtube-redirect',
      element: <YoutubeRedirect />,
    },
    {
      path: 'gbusiness-redirect',
      element: <GoogleMyBusinessRedirect />,
    },
    {
      path: 'linkedin-redirect',
      element: <LinkedInRedirect />,
    },
    {
      path: 'pinterest-redirect',
      element: <PinterestRedirect />,
    },
    {
      path: 'google-redirect',
      element: <GoogleRedirect />,
    },
    {
      path: 'threads-redirect',
      element: <ThreadsRedirect />
    },
    {
      path: 'tiktok-redirect',
      element: <TikTokRedirect />,
    },
    {
      path: 'payment-redirect',
      element: <PaymentRedirect />,
    },
    {
      path: 'payment-failure',
      element: <PaymentFailureComponent />,
    },
    {
      path: 'payment-success',
      element: <PaymentSuccessComponent />,
    },
    {
      path: 'posts/:urlKey',
      element: <PublicApproval />
    },
    {
      path: 'scheduled-report',
      element: <ScheduledReport/>
    },
  ]
}

export const Routes = {
  PublicRoutes,
  PrivateRoute
}
