import { Box, Button, Checkbox, Divider, FormControlLabel, FormGroup, TableContainer, IconButton, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AddonPaymentComponent from 'src/components/stripe/addon-payment.component';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import { useToaster } from 'src/utils/toaster/toasterContext';

const UpgradeSubscriptionComponent = ({ planAddonDetails, currency }) => {

  const { showToast } = useToaster();

  const UpgradeStruct = () => {
    return {
      no_of_social_accounts: 0,
      no_of_users: 0,
      no_of_brands: 0,
      no_of_scheduled_posts: 0,
      no_of_drafts: 0,
      no_of_storage: 0,
      has_cname: false,
    };
  };

  // State variable
  const [addons, setAddons] = useState(planAddonDetails);
  const [upgradeItems, setUpgradeItems] = useState(UpgradeStruct);
  const [totalAmount, setTotalAmount] = useState(0);
  const [expiresOn, setPlanExpiresOn] = useState('');
  const [hasActiveSubscription, setActiveSubscription] = useState(false);
  const [isPaymentOn, setPaymentOn] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getAddonPrice();
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [upgradeItems,currency, 500]);

  const handleInputChange = (value, addon) => {
    let items = { ...upgradeItems };
    items[addon.name] = value ? parseInt(value) : 0;
    setUpgradeItems(items);
  };
  const handleIncrement = (addon) => {
    handleInputChange(upgradeItems[addon.name] + 1, addon);
  };

  const handleDecrement = (addon) => {
    handleInputChange(Math.max(0, upgradeItems[addon.name] - 1), addon); // Ensure value doesn't go below 0
  };
  const handleWhiteLabel = () => {
    let items = { ...upgradeItems };
    items["has_cname"] = !items["has_cname"];
    setUpgradeItems(items);
  }

  const failureCallback = (message) => {
    message && showToast(message);
  };

  const successCallback = (data, message) => {
    setTotalAmount(data.amount);
    setPlanExpiresOn(data.expire_date);
    setActiveSubscription(data.has_active_subscription);
  };

  const getAddonPrice = () => {
    let postProps = {
      url: userModuleURL.getAddonUpgradePrice + currency,
      body: upgradeItems,
      successCallback: successCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };

  const handlePayment = (show) => {
    setPaymentOn(show);
  };

  return (
    <>
      <TableContainer
        sx={{
          maxHeight: 'calc(94vh - 280px)',
          minHeight: 'calc(94vh - 280px)',
        }}
      >
        {addons &&
          addons.filter((addon) => !["no_of_draft", "has_cname"].includes(addon.name))
            .map((addon) => {
              return (
                <>
                  {/* <Box key={addon.Value} sx={{ mb: 1, pl: 2, pr: 2 }}>
                  <Typography sx={{ fontWeight: 500 }}>{addon.DisplayName}</Typography>
                  <TextField
                    fullWidth
                    name={addon.Value}
                    placeholder={`Enter ${addon.DisplayName}`}
                    onChange={(event) => handleInputChange(event, addon)}
                    variant="outlined"
                    type="number"
                    sx={{
                      '& .MuiInputBase-input': {
                        padding: '10px 12px',
                      },
                    }}
                  />
                </Box> */}

                  <Box key={addon.id} sx={{ mb: 1, pl: 2, pr: 2 }}>
                    <Typography>{CommonHelper.DisplayPlanAddonName(addon.name)}</Typography>
                    <Box
                      key={addon.id}
                      display="flex"
                      alignItems="center"
                      mb={1}
                      sx={{
                        borderRadius: '4px',
                        padding: '4px',
                        justifyContent: 'space-between',
                        backgroundColor: 'background.default',
                      }}
                    >
                      <IconButton
                        onClick={() => handleDecrement(addon)}
                        sx={{
                          color: 'black',
                          backgroundColor: 'white',
                          borderRadius: 1,
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                      <TextField
                        name={addon.name}
                        value={upgradeItems[addon.name] * addon.quantity || 0}
                        onChange={(event) => handleInputChange(event.target.value, addon)}
                        size="small"
                        inputProps={{ style: { textAlign: 'center' } }}
                        sx={{
                          ml: 1,
                          mr: 1,
                          width: '100%',
                          '& .MuiInputBase-root': {
                            color: 'black',
                            backgroundColor: 'white',
                            borderRadius: 1,
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                          },
                        }}
                      />
                      <IconButton
                        onClick={() => handleIncrement(addon)}
                        sx={{
                          color: 'black',
                          backgroundColor: 'white',
                          borderRadius: 1,
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </>
              );
            })}

        <Box key="white-label" sx={{ mb: 1, pl: 2, pr: 2 }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleWhiteLabel}
                />
              }
              label="White Label"

            />
          </FormGroup>
        </Box>
      </TableContainer>
      <Box sx={{ p: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography sx={{ fontWeight: 700, color: 'text.secondary' }}>Expires On:</Typography>
          <Typography sx={{ color: 'text.secondary' }}>{expiresOn ? CommonHelper.formatDateAndTime(expiresOn, 'date') : '-'}</Typography>
        </Box>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography sx={{ fontWeight: 700 }}>Total Amount:</Typography>
          <Typography>{currency === "INR" ? `₹${totalAmount}` : `$${totalAmount}`}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
          {hasActiveSubscription && (
            <Button variant="contained" onClick={() => handlePayment(true)} sx={{ mb: 0.5 }}>
              Pay
            </Button>
          )}
        </Box>
      </Box>
      {isPaymentOn && (
        <AddonPaymentComponent handleClose={handlePayment} addonUpgrade={upgradeItems} amount={totalAmount} currency={currency} />
      )}
    </>
  );
};
export default UpgradeSubscriptionComponent;
