import { Avatar, Badge, Card, CardContent, CardMedia, Stack, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react'; // Import useState and useEffect
import { CommonHelper } from 'src/utils/commonHelper';
import NoMediafiles from '../../../assets/no-media.svg';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const BestPostCardDetails = ({
    title,
    mediaUrl,
    reactions = 0,
    shares = 0,
    comments = 0,
    createdTime,
    description,
    engagement,
    link,
    engagementRate,
}) => {
    const theme = useTheme();
    const [blobUrl, setBlobUrl] = useState(null);

    useEffect(() => {
        const fetchMediaAsBlob = async () => {
            if (mediaUrl) {
                try {
                    const response = await fetch(mediaUrl);
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    setBlobUrl(url);
                    return () => URL.revokeObjectURL(url);
                } catch (error) {
                    console.error('Error fetching media:', error);
                }
            }
        };

        fetchMediaAsBlob();
    }, [mediaUrl]);

    return (
        <Card sx={{ maxWidth: 500, display: 'flex', flexDirection: 'column', width: '100%' }}>
            {/* Card Header: Title and Created Time */}
            <CardContent>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5" color="textSecondary" gutterBottom>
                        {title}
                    </Typography>
                    <Stack direction="row" justifyContent="space-between" gap='0.2rem'>
                        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                            {CommonHelper.DateTimeFromCurrentTime(createdTime)}
                        </Typography>
                        <a href={link} target="_blank" rel="noopener noreferrer" style={{ marginLeft: 'auto' }}>
                            <OpenInNewIcon sx={{ color: 'primary.main' }} fontSize='small' />
                        </a>
                    </Stack>
                </Stack>

                {/* Card Description */}
                <Typography
                    sx={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: '0.9rem',
                        whiteSpace: 'pre-line',
                        marginBottom: theme.spacing(2),
                        color: 'textSecondary',
                    }}
                >
                    {description}
                </Typography>
            </CardContent>

            {/* Card Media: Image */}

            <CardMedia
                component="img"
                image={blobUrl || NoMediafiles}
                alt={title}
                sx={{
                    height: 150,
                    padding: theme.spacing(1), // Added padding for image
                    objectFit: 'cover',
                }}
            />


            {/* Card Footer: Reactions, Comments, Shares */}
            <CardContent sx={{ paddingTop: theme.spacing(1.5) }}>
                <Stack spacing={1.5} direction="row" justifyContent="space-between">
                    <Typography variant="body2" color="textPrimary">Reactions</Typography>
                    <Typography variant="body2">{reactions}</Typography>
                </Stack>
                <Stack spacing={1.5} direction="row" justifyContent="space-between">
                    <Typography variant="body2" color="textPrimary">Comments</Typography>
                    <Typography variant="body2">{comments}</Typography>
                </Stack>
                <Stack spacing={1.5} direction="row" justifyContent="space-between">
                    <Typography variant="body2" color="textPrimary">Shares</Typography>
                    <Typography variant="body2">{shares}</Typography>
                </Stack>
                <Stack spacing={1.5} direction="row" justifyContent="space-between">
                    <Typography variant="body2" color="textPrimary">Engagement</Typography>
                    <Typography variant="body2">{engagement}</Typography>
                </Stack>
                <Stack spacing={1.5} direction="row" justifyContent="space-between">
                    <Typography variant="body2" color="textPrimary">Engagement Rate</Typography>
                    <Typography variant="body2">{engagementRate}%</Typography>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default BestPostCardDetails;
