import {
    Card,
    Table,
    TableContainer,
    TablePagination,
    Grid,
    Typography,
    Avatar,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Paper,
    Stack,
    useMediaQuery,
    useTheme,
    Divider,
    createTheme,
    Box,
    Tooltip,
    IconButton,
    Tab,
    Tabs
} from '@mui/material';
import { useState } from 'react';
import HttpServices from 'src/services/httpService';
import { userModuleURL, imageModeuleUrl, postsModuleURL } from 'src/services/urlService';
import { useToaster } from 'src/utils/toaster/toasterContext';
import NoApproval from '../../../assets/app-image/no-approval-posts.svg';
import { PostDetailsHelper } from 'src/utils/postDetailsHelper';
import { CommonHelper } from 'src/utils/commonHelper';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AccountBoxSharp as AccountBoxSharpIcon } from '@mui/icons-material';
import { useRightSidebar } from 'src/layouts/right-side-bar/rightSidebarProvider';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import PublicPostPreview from '../public-post-preview.component';
import PublicPostComments from '../public-post-comment.component';
import { useParams, useNavigate } from 'react-router-dom';
import { capitalCase } from "change-case";
import ConfirmationDialog from "src/components/ConfirmationDialog";
import PendingApprovalGridActionPopup from './pending-approval-grid-action-popup.component';

const PendingApprovalGrid = ({ pendingApprovalList, handleReCallPendingApprovalPost, publicUserName, order, orderBy }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    //Table Variables
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedPopOverPost, setPopOverPost] = useState(null);
    const [popoverElement, setPopoverElement] = useState(null);
    const [height, setHeight] = useState(window.innerHeight - 110);
    const [loading, setLoading] = useState(false);
    const [confirmationDialogue, setConfirmationDialogue] = useState(null);
    const { showToast } = useToaster();
    const respwidth = useMediaQuery(theme.breakpoints.up('xl'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { urlKey } = useParams();
    const POPUP_WIDTH = isMobile ? '300px' : '500px';

    const ProfileTime = (scheduledTime) => {
        return (
            <>
                <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ mt: 1 }}>
                    {CommonHelper.formatDateAndTime(scheduledTime)}
                </Stack>
            </>
        );
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        let orderStr = isAsc ? 'desc' : 'asc'
        handleReCallPendingApprovalPost(property, orderStr)
    };

    const handleCloseMenu = () => {
        setPopOverPost(null);
        setPopoverElement(null);
    };

    const closeConfirmationDialogue = () => {
        setConfirmationDialogue(null)
    };

    const failureCallback = (message) => {
        message && showToast(message, 'e');
        handleCloseMenu();
        closeConfirmationDialogue()
        setLoading(false);
    };

    const fetchMediaMetaData = (data, editAsNew = false) => {
        let mediaBody = {
            media_urls: data?.media_url.split(",")
        };
        let postProps = {
            url: imageModeuleUrl.fetchMediaMetadata,
            body: mediaBody,
            successCallback: (imageData, message) => { fetchMediaSuccessCallback(imageData, message, data, editAsNew) },
            failureCallback: failureCallback
        }
        HttpServices.Post(postProps);
    }
    const fetchMediaSuccessCallback = (imageData, mesaage, data, editAsNew) => {
        if (editAsNew) {
            uploadEditDataToNewPost(data, imageData ? imageData.media_datas : []);
        }
        else {
            uploadDataToPost(data, imageData ? imageData.media_datas : []);
        }

    }
    const newPostSucessCallback = (data) => {
        if (data.media_url && data.media_url.length > 0)
            fetchMediaMetaData(data);
        else
            uploadDataToPost(data);
    }

    const editNewPostSucessCallback = (data) => {
        let editAsNew = true
        if (data.media_url && data.media_url.length > 0)
            fetchMediaMetaData(data, editAsNew);
        else
            uploadEditDataToNewPost(data);
    }

    const uploadDataToPost = (data, imageData = []) => {
        setLoading(false);
        const updatedData = {
            ...data,
            media_data: imageData,
            schedule_id: pendingApprovalList?.id
        };
        const newPostData = CommonHelper.CommonNewPostAddMediaAllService(updatedData, imageData)
        navigate('/new-post', { state: { data: newPostData } });
    }
    const uploadEditDataToNewPost = (data, imageData = []) => {
        setLoading(false);
        const newPostData = CommonHelper.CommonNewPostAddMediaAllService(data, imageData)
        navigate('/new-post', { state: { data: newPostData } });
    }
    const handleEditPost = () => {
        setLoading(true);
        CommonHelper.CommonNewPostStruct(pendingApprovalList.id, newPostSucessCallback, failureCallback)
    };
    const handleEditAsNewPost = () => {
        setLoading(true);
        CommonHelper.CommonNewPostStruct(pendingApprovalList.id, editNewPostSucessCallback, failureCallback)
    };

    const handleApprovePost = (row) => {
        handleCloseMenu()
        setConfirmationDialogue({
            "isOpen": true,
            "title": "Confirm Post Now",
            "message": "Are you sure you want to Approve this Schedule?",
            submitFunction: () => confirmApprovePost(row)
        })
    };

    const confirmApprovePost = (row) => {
        let props = {
            schedule_id: row.id,
            name: publicUserName,
        };
        let postProps = {
            url: userModuleURL.approvePostPublic + `?url_key=${urlKey}`,
            body: props,
            successCallback: approvePostSuccessCallback,
            failureCallback,
        };
        HttpServices.Post(postProps);
    };

    const approvePostSuccessCallback = (data, message) => {
        message && showToast(message);
        handleCloseMenu();
        closeConfirmationDialogue()
        handleReCallPendingApprovalPost();
    };


    const handleDeletePost = () => {
        handleCloseMenu()
        setConfirmationDialogue({
            "isOpen": true,
            "title": "Confirm Deletion",
            "message": "Are you sure you want to delete this Schedule?",
            "submitFunction": confirmDeletePost
        })
    };

    const confirmDeletePost = () => {
        let postProps = {
            url: postsModuleURL.deleteSchedule + pendingApprovalList.id,
            successCallback: deleteSuccessCallback,
            failureCallback,
        };
        HttpServices.Post(postProps);
        closeConfirmationDialogue()
    };


    const deleteSuccessCallback = (data, message) => {
        message && showToast(message);
        handleCloseMenu();
        closeConfirmationDialogue()
        handleReCallPendingApprovalPost();
    };


    const handleSaveAsDraft = () => {
        let props = {
            schedule_post_id: pendingApprovalList.id,
        };
        let postProps = {
            url: postsModuleURL.savePostAsDraft,
            body: props,
            successCallback: saveAsDraftSuccessCallback,
            failureCallback,
        };
        HttpServices.Post(postProps);
    };

    const saveAsDraftSuccessCallback = (data, message) => {
        message && showToast(message);
        handleCloseMenu();
    };

    const handleRejectPost = (row) => {
        handleCloseMenu()
        setConfirmationDialogue({
            "isOpen": true,
            "title": "Confirm Deletion",
            "message": "Are you sure you want to Reject this Schedule?",
            "submitFunction": () => confirmRejectPost(row)
        })

    };

    const confirmRejectPost = (row, message) => {
        let props = {
            schedule_id: row.id,
            name: publicUserName,
        };
        let postProps = {
            url: userModuleURL.rejectPostPublic + `?url_key=${urlKey}`,
            body: props,
            successCallback: unapproveSuccessCallback,
            failureCallback,
        };
        HttpServices.Post(postProps);
    }

    const unapproveSuccessCallback = (data, message) => {
        message && showToast(message, 's');
        handleCloseMenu();
        closeConfirmationDialogue()
        handleReCallPendingApprovalPost();
    };

    const postActionProps = {
        popoverElement: popoverElement,
        popoverElement,
        handleCloseMenu,
        approvePost: handleApprovePost,
        editAsNewPost: handleEditAsNewPost,
        editPost: handleEditPost,
        rejectPost: handleRejectPost,
        saveAsDraft: handleSaveAsDraft,
        deletePost: handleDeletePost,
        loading,
        selectedPopOverPost: selectedPopOverPost,
    };


    const handleOpenScheduledPost = (row, open = false) => {
        let data = {
            content: postView,
            data: row,
        };
        showRightSidebar(data, POPUP_WIDTH);
    };

    const postView = (row) => {
        let postDetails = {
            data: { id: row },
        };
        return <PublicDetailsPreview postDetails={postDetails} />;
    };

    const { showRightSidebar } = useRightSidebar();
    const ACCOUNT_TABS = (postDetails) => [
        {
            value: 'Preview Event',
            icon: <AccountBoxSharpIcon sx={{ width: 25, height: 25, mb: '0 !important' }} />,
            component: <PublicPostPreview postDetails={postDetails} urlKey={urlKey} />,
        },
        {
            value: 'Review Comments',
            icon: <AccountBoxSharpIcon sx={{ width: 25, height: 25, mb: '0 !important' }} />,
            component: <PublicPostComments PostDetails={postDetails} urlKey={urlKey} publicUserName={publicUserName} />,
        }
    ];

    const PublicDetailsPreview = ({ postDetails }) => {
        const tabs = ACCOUNT_TABS(postDetails);
        const [currentTab, setCurrentTab] = useState(tabs[0].value);

        const handleChange = (event, newValue) => {
            setCurrentTab(newValue);
        };

        return (
            <Box>
                <Tabs
                    value={currentTab}
                    onChange={handleChange}
                    scrollButtons="auto"
                    variant="scrollable"
                    sx={{ gap: '0.5rem', minHeight: '37px' }}
                    allowScrollButtonsMobile
                >
                    {tabs.map((tab) => (
                        <Tab
                            key={tab.value}
                            value={tab.value}
                            label={tab.value}
                            icon={tab.icon}
                            sx={{
                                flexDirection: 'row',
                                gap: '0.25rem',
                                padding: 1,
                                minHeight: '10px',
                                mr: '0.75rem',
                                ml: '0.75rem',
                                fontWeight: 600,
                            }}
                            disableRipple
                        />
                    ))}
                </Tabs>
                <Box sx={{ mb: 2 }} />
                <Box>
                    {tabs.find((tab) => tab.value === currentTab)?.component}
                </Box>
            </Box>
        );
    };

    return (
        <>
            {pendingApprovalList && pendingApprovalList.length > 0 ? (
                <>
                    <Box style={{ justifyContent: 'center', alignItems: 'center', minHeight: 'calc(100vh - 80px)', maxHeight: 'calc(100vh - 80px)', overflowY: 'auto' }}>
                        {pendingApprovalList.map(row => {
                            return (
                                <Stack container justifyContent="center" alignItems="center" >
                                    <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '0.5rem', pb: 2 }}>
                                        {!isMobile && PostDetailsHelper.getProfile(row.created_by.name, row.created_by.email, row.created_by.profile_pic)}
                                        <Stack
                                            onClick={() => {
                                                handleOpenScheduledPost(row.id, true);
                                            }}
                                        >
                                            <Card
                                                sx={{
                                                    maxWidth: !isMobile ? 500 : 340,
                                                    minWidth: !isMobile ? 500 : 340,
                                                    boxShadow: 'none',
                                                    border: 'solid 2px',
                                                    borderColor: theme.palette.preview.postcard.color,
                                                }}
                                            >
                                                <CardHeader
                                                    title={PostDetailsHelper.getSocialMediaProfiles(row?.connected_profile_details)}
                                                    subheader={<ProfileTime scheduledTime={row?.scheduled_time} />}
                                                    action={<IconButton
                                                        size="large"
                                                        color="inherit"
                                                        sx={{ padding: '6px', marginRight: '2px' }}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>}
                                                />
                                                <CardContent sx={{ pt: 0, pb: 0 }}>
                                                    <Typography
                                                        style={{
                                                            display: '-webkit-box',
                                                            WebkitBoxOrient: 'vertical',
                                                            WebkitLineClamp: 2,
                                                            overflow: 'hidden',
                                                            WebkitBoxDecorationBreak: 'clone',
                                                            whiteSpace: 'pre-line',
                                                            textOverflow: 'ellipsis',
                                                        }}
                                                    >
                                                        {row.description}
                                                    </Typography>
                                                </CardContent>
                                                <CardMedia sx={{ pl: 2, pr: 2 }}>{PostDetailsHelper.getPublicPostMedias(row?.media_url)}</CardMedia>
                                                <CardActions disableSpacing>
                                                    <Divider />
                                                    <Grid container spacing={2} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <Grid item xs={3}>
                                                            <Paper
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    padding: '8px',
                                                                    cursor: 'pointer',
                                                                    transition: 'background-color 0.3s',
                                                                    '&:hover': {
                                                                        backgroundColor: theme.palette.secondary.main,
                                                                    },
                                                                    color: theme.palette.primary.main,
                                                                }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleApprovePost(row);
                                                                }}
                                                            >
                                                                <CheckCircleOutlinedIcon />
                                                                {!isMobile && <Typography sx={{ fontWeight: 700, ml: 1 }}>Approve</Typography>}
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Paper
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    padding: '8px',
                                                                    cursor: 'pointer',
                                                                    transition: 'background-color 0.3s',
                                                                    '&:hover': {
                                                                        backgroundColor: theme.palette.secondary.main,
                                                                    },
                                                                    color: theme.palette.error.main,
                                                                }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleRejectPost(row);
                                                                }}
                                                            >
                                                                <RemoveCircleOutlineOutlinedIcon />
                                                                {!isMobile && <Typography sx={{ fontWeight: 700, ml: 1 }}>Reject</Typography>}
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Paper
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    padding: '8px',
                                                                    cursor: 'pointer',
                                                                    transition: 'background-color 0.3s',
                                                                    '&:hover': {
                                                                        backgroundColor: theme.palette.secondary.main,
                                                                    },
                                                                    color: theme.palette.comments
                                                                }}
                                                            >
                                                                <QuestionAnswerRoundedIcon />
                                                                {!isMobile && <Typography sx={{ fontWeight: 700, ml: 1 }}>Review Comments</Typography>}
                                                            </Paper>
                                                        </Grid>
                                                    </Grid>
                                                </CardActions>
                                            </Card>
                                        </Stack>
                                    </Stack>
                                    <PendingApprovalGridActionPopup {...postActionProps} approvePostDetails={row} />
                                </Stack>)
                        })}
                        {confirmationDialogue && <ConfirmationDialog
                            open={confirmationDialogue.isOpen}
                            onClose={() => closeConfirmationDialogue()}
                            onConfirm={confirmationDialogue.submitFunction}
                            title={confirmationDialogue.title}
                            message={confirmationDialogue.message}
                        />}
                    </Box>
                </>
            ) : (
                <Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)' }}>
                    <img src={NoApproval} style={{ width: '50%', height: '50%', maxWidth: '50%', maxHeight: '50%' }} alt="No Pending Approval" />
                </Card>
            )}
        </>
    );
};
export default PendingApprovalGrid;
