import { Upload as UploadIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import ImageDraggable from 'src/components/image/image-draggable';
import LinearProgressBar from 'src/components/progress-bar/progressBar.component';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import HttpServices from 'src/services/httpService';
import { imageModeuleUrl, userModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import NoMediaLibrary from '../../assets/app-image/no-media-library.svg';
import MediaFileComponent from './media-files.component';
import MediaUploadComponent from './media-upload.component';

const MediaLibrary = ({ isFromNewPost = false, handleMediaFiles = null }) => {
  const [loader, setLoader] = useState(true);
  const [height, setHeight] = useState(window.innerHeight - 190);
  const [mediaUrls, setMediaUrls] = useState([]);
  const [mediaFiles, setMediaFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isShowUploadMedia, setUploadMedia] = useState(false);
  const [mediaData, setMediaData] = useState([]);
  const [isUploadLoading, setUploadLoading] = useState(false);
  const [fileUploadprogress, setFileUploadprogress] = useState(0);

  const { isCollapse } = useCollapseDrawer();
  const navigate = useNavigate();
  const { showToast } = useToaster();

  useEffect(() => {
    getAllMedia();
  }, []);

  const getAllMedia = () => {
    let mediaProps = {
      url: userModuleURL.getAllMedia,
      successCallback,
      failureCallback,
    };
    HttpServices.Get(mediaProps);
  };
  const successCallback = (data, message) => {
    // setLoader(false);
    setMediaUrls(data);
    prepareMediaData(data);
  };
  const prepareMediaData = async (files) => {
    const data = await Promise.all(
      files.map(async (media) => {
        //TODO - Need to change the media.media_url to media.thumbnail_url
        const thumbnail_url = await HttpServices.GetImageFromAPI(media.thumbnail_url);
        return { ...media, thumbnail_url };
      })
    );
    setMediaData(data);
    setLoader(false);
  };
  const deleteMediaSuccessCallback = (data, message) => {
    message && showToast(message, 's');
  };
  const failureCallback = (message) => {
    message && showToast(message, 'e');
  };
  const handleDeleteMedia = (media) => {
    const urlObj = new URL(media.media_url);
    const path = urlObj.searchParams.get('path');
    let mData = [...mediaData];
    let index = mData.findIndex((a) => a.media_url === media.media_url);
    if (index >= 0) mData.splice(index, 1);
    setMediaData([...mData]);
    let media_urls = {
      media_paths: [path],
    };
    let mediaProps = {
      url: imageModeuleUrl.deleteMedia,
      body: media_urls,
      deleteMediaSuccessCallback,
      failureCallback,
    };
    HttpServices.Post(mediaProps);
  };
  const handleCloseUploadMedia = () => {
    setUploadMedia(false);
  };
  const handleSelectFile = async (event, media) => {
    let files = [...selectedFiles];
    let isChecked = event.target.checked;
    if (isChecked) {
      files.push(media);
    } else {
      let index = files.findIndex((a) => a.media_url === media.media_url);
      if (index >= 0) {
        files.splice(index, 1);
      }
    }
    setSelectedFiles(files);
    if (isFromNewPost) {
      handleMediaFiles(media, isChecked);
    }
  };
  const handleMediaUpload = (files) => {
    let mFiles = [...mediaFiles];
    mFiles = mFiles.concat(files);
    setMediaFiles([...mFiles]);
  };
  const handleReOrderFiles = (oldIndex, newIndex) => {
    setMediaFiles((prevImages) => {
      const newImages = [...prevImages];
      return CommonHelper.ReArrangeArray(newImages, oldIndex, newIndex);
    });
  };
  const handleRemoveFiles = (file) => {
    let files = [...mediaFiles];
    let index = files.findIndex((a) => a.path === file.path);
    if (index >= 0) files.splice(index, 1);
    setMediaFiles([...files]);
  };
  const fileUploadSuccessCallback = () => {
    setMediaFiles([]);
    handleCloseUploadMedia();
    getAllMedia();
  };
  const handleUploadMediaFiles = () => {
    if (mediaFiles?.length > 0) {
      setUploadLoading(true);
      setFileUploadprogress(1);
      let formData = new FormData();
      for (var i = 0; i < mediaFiles.length; i++) {
        formData.append('fileupload', mediaFiles[i]);
      }
      let mediaProps = {
        formData,
        setFileUploadprogress,
        successCallback: fileUploadSuccessCallback,
        failureCallback,
      };
      HttpServices.UploadMediaFiles(mediaProps);
    } else {
      handleCloseUploadMedia();
    }
  };
  const serverUploadSuccessCallback = (data, message) => {
    message && showToast(message);
  };
  const handleUploadMediaNewPost = () => {
    let privateFiles = [...selectedFiles];
    const uploadFilesProps = {
      privateFiles,
      successCallback: fetchMediaSuccessCallback,
      failureCallback,
    };
    CommonHelper.UploadPrivateMediaFilesToServer({ ...uploadFilesProps });
  };
  const fetchMediaSuccessCallback = (data, message) => {
    const updatedData = {
      media_data: data.media_datas,
    };
    navigate('/new-post', { state: { media_data: updatedData } });
  };
  const handleUploadToServer = async () => {
    if (selectedFiles?.length > 0) {
      setFileUploadprogress(1);
      let formData = new FormData();
      for (var i = 0; i < selectedFiles.length; i++) {
        let file = await CommonHelper.GetFileFromUrl(selectedFiles[i].media_url);
        formData.append('fileupload', file);
      }
      let mediaProps = {
        formData,
        setFileUploadprogress,
        successCallback: serverUploadSuccessCallback,
        failureCallback,
      };
      HttpServices.UploadMediaFiles(mediaProps);
    }
  };
  return (
    <>
      <Helmet>
        <title>Media Library</title>
      </Helmet>
      {isFromNewPost && (
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'wrap', gap: 2, p: 2 }}
        >
          {mediaUrls?.length > 0 && mediaData?.length > 0 ? (
            <>
              <MediaFileComponent
                mediaData={mediaData}
                handleDelete={handleDeleteMedia}
                handleUpload={handleUploadToServer}
                handleSelectFile={handleSelectFile}
                selectedFiles={selectedFiles}
                isFromNewPost={isFromNewPost}
              />
              {isFromNewPost && fileUploadprogress > 0 && <LinearProgressBar value={fileUploadprogress} />}
            </>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={NoMediaLibrary} alt="NoMediaLibrary" width={220} />
            </Box>
          )}
        </Box>
      )}
      {!isFromNewPost &&
        (loader ? (
          <Spinner />
        ) : (
          <>
            <Card sx={{ height: 'calc(100vh - 190px)' }}>
              {mediaUrls?.length > 0 && mediaData?.length > 0 ? (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexWrap: 'wrap',
                      gap: 2,
                      p: 2,
                      maxHeight: 'calc(100vh - 160px)',
                      overflow: 'auto',
                    }}
                  >
                    <MediaFileComponent
                      mediaData={mediaData}
                      handleDelete={handleDeleteMedia}
                      handleUpload={handleUploadToServer}
                      handleSelectFile={handleSelectFile}
                      selectedFiles={selectedFiles}
                      isFromNewPost={isFromNewPost}
                    />
                  </Box>
                  {isFromNewPost && fileUploadprogress > 0 && <LinearProgressBar value={fileUploadprogress} />}
                </>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img src={NoMediaLibrary} alt="NoMediaLibrary" height={height} />
                </Box>
              )}
            </Card>
            <Card style={{ width: '100%', marginTop: '1rem', padding: '0.7rem' }} isCollapse={isCollapse}>
              <Stack
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ height: '3rem' }}
              >
                <LoadingButton
                  variant="contained"
                  endIcon={<UploadIcon sx={{ width: '20px', height: '20px' }} />}
                  onClick={() => setUploadMedia(true)}
                >
                  Upload To Media Library
                </LoadingButton>
                {selectedFiles?.length > 0 && (
                  <LoadingButton
                    variant="contained"
                    endIcon={<UploadIcon sx={{ width: '20px', height: '20px' }} />}
                    onClick={handleUploadMediaNewPost}
                  >
                    Upload Image To New Post
                  </LoadingButton>
                )}
              </Stack>
            </Card>
          </>
        ))}

      {/* Popup Place */}
      {isShowUploadMedia && (
        <Dialog open={isShowUploadMedia} onClose={handleCloseUploadMedia}>
          <DialogTitle>Upload To Image Library</DialogTitle>
          <DialogContent>
            <MediaUploadComponent handleMediaFiles={handleMediaUpload} isUploadToImageServer={false} />
            {mediaFiles?.length > 0 && (
              <Box>
                <ImageDraggable
                  mediaFiles={mediaFiles}
                  handleRemoveFiles={handleRemoveFiles}
                  handleReOrderFiles={handleReOrderFiles}
                  isFile={true}
                />
              </Box>
            )}
            {fileUploadprogress > 0 && <LinearProgressBar value={fileUploadprogress} />}
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="error" onClick={handleCloseUploadMedia}>
              Cancel
            </Button>
            <LoadingButton variant="contained" isLoading={isUploadLoading} onClick={handleUploadMediaFiles} autoFocus>
              Upload
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
export default MediaLibrary;
