import {
    Avatar,
    Box,
    Card, Chip,
    Stack,
    Typography,
    useMediaQuery,
    Paper,
    Button,
    TextField,
    InputAdornment,
    Container,
    Tab,
    Tabs,
} from '@mui/material';
import { Comment as CommentIcon, Send as SendIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState, useRef } from 'react';
import { useRightSidebar } from 'src/layouts/right-side-bar/rightSidebarProvider';
import HttpServices from 'src/services/httpService';
import { postsModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import { useNavigate } from 'react-router-dom';
import Rating from '@mui/material/Rating';
import DynamicHeightComponent from 'src/utils/dynamicSize.component';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import EmojiPicker from 'src/components/EmojiPicker';
import { LoadingButton } from '@mui/lab';
import ReviewsGrid from '../reviews-questions/review.component';
import GBusiness_Icon from '../../assets/google-my-business-logo.svg';
import QuestionsGrid from '../reviews-questions/questions.component';


const GbusinessChat = ({ connectedNetworks }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const [height, setHeight] = useState(window.innerHeight - 250);
    const [isLoading, setLoading] = useState(true);
    const [gBusinessId, setGBusinessId] = useState('');
    const [connectedMedias, setConnectedMedias] = useState([]);
   

    useEffect(() => {
        let gbusinessId = connectedNetworks && connectedNetworks.length > 0 && connectedNetworks[0].id;
        gbusinessId && setGBusinessId(gbusinessId);
        setConnectedMedias(connectedNetworks);
        connectedNetworks && connectedNetworks.length === 0 && setLoading(false);
    }, [connectedNetworks]);

   
    const handleFacebookPage = (id) => {
        setGBusinessId(id);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const navigateToIntegration = () => {
        navigate('/integration');
    }

    const a11yProps = (index) => {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      };
    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ pt: 3, pb: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      };
   
    const getNetworkChip = () => {
        return (
            connectedMedias &&
            connectedMedias.length > 0 &&
            connectedMedias.map((c) => {
                let isActive = c.id === gBusinessId;
                return (
                    <Chip
                        style={{
                            borderRadius: 8,
                            opacity: isActive ? 1 : 0.9,
                            ...(
                                isActive && {
                                    backgroundColor: theme.palette.primary.main, color: 'white',
                                    fontWeight: 800
                                }
                            )
                        }}
                        sx={{ mr: 1, mb: 1 }}
                        onClick={() => { handleFacebookPage(c.id) }}
                        avatar={<Avatar
                            src={c.profile_image ? c.profile_image : c.profile_name || c.id}
                            alt={c.profile_name ? c.profile_name : c.id}
                            sx={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                height: 40,
                                width: 40,
                                bgcolor: isActive ? 'white' : theme.palette.primary.main,
                            }}
                            style={{ color: isActive ? theme.palette.primary.main : 'white' }}
                        />}
                        label={c.profile_name ? c.profile_name : c.id}
                    />
                )
            })
        )
    }

    const isMobileIcon = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Box>
            {connectedMedias && connectedMedias.length > 0 ?
                <>
                    <Card sx={{ mb: 2, p: 2, }} >
                        {getNetworkChip()}
                    </Card>
                    <Card sx={{maxHeight: 'calc(100vh - 240px)',minHeight: 'calc(100vh - 240px)'}}>
                        {/* {isLoading ?
                            <Spinner />
                            :
                            reviews?.length === 0 ?
                                <Typography>No Reviews Found</Typography>
                                : getGridView()} */}
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    variant="scrollable"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                    aria-label="scrollable force tabs example"
                                    sx={{ borderBottom: 1, borderColor: 'divider' }}
                                >
                                    <Tab
                                        icon={<img src={GBusiness_Icon} width={28} height={20} />}
                                        iconPosition="start"
                                        label={isMobileIcon ? '' : 'Review'}
                                        {...a11yProps(0)}
                                    />
                                    <Tab
                                        icon={<img src={GBusiness_Icon} width={28} height={20} />}
                                        iconPosition="start"
                                        label={isMobileIcon ? '' : 'Questions'}
                                        {...a11yProps(1)}
                                    />

                                </Tabs>
                                <TabPanel value={value} index={0}>
                                   <ReviewsGrid gBusinessId={gBusinessId}/>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                  <QuestionsGrid  gBusinessId={gBusinessId}/>
                                </TabPanel>
                        </Box>
                    </Card>
                </>
                :
                isLoading ?
                    <Spinner />
                    :
                    <Card sx={{ mb: 2, p: 2, display: 'flex',maxHeight: 'calc(100vh - 150px)',minHeight: 'calc(100vh - 150px)', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                            variant="contained"
                            autoFocus
                            onClick={navigateToIntegration}
                        >
                            Connect GoogleMyBusiness Accounts
                        </Button>
                    </Card>}
        </Box>
    )
}
export default GbusinessChat;
