import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Stack, Button, Tooltip, Typography, IconButton, ToggleButton } from '@mui/material';
import useResponsive from 'src/hooks/useResponsive';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SharePublicApprovalPopup from '../public-approval/share-approval.popup';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { useToaster } from 'src/utils/toaster/toasterContext';
import { CommonHelper } from 'src/utils/commonHelper';
import ShareIcon from '@mui/icons-material/Share';


const VIEW_OPTIONS = [
  { value: 'dayGridMonth', label: 'Month', icon: 'ic:round-view-module' },
  // { value: 'timeGridWeek', label: 'Week', icon: 'ic:round-view-week' },
  // { value: 'timeGridDay', label: 'Day', icon: 'ic:round-view-day' },
  { value: 'listWeek', label: 'Agenda', icon: 'ic:round-view-agenda' },
];

const getViewIcon = (iconName) => {
  switch (iconName) {
    case 'ic:round-view-module':
      return <ViewModuleIcon sx={{ width: "20px", height: "20px"}}/>
    case 'ic:round-view-week':
      return <ViewWeekIcon sx={{ width: "20px", height: "20px"}}/>
    case 'ic:round-view-day':
      return <ViewDayIcon sx={{ width: "20px", height: "20px"}}/>
    case 'ic:round-view-agenda':
      return <ViewAgendaIcon sx={{ width: "20px", height: "20px"}}/>
    default:
      return null;
  }
};
const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2.5),
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

export default function CalendarToolbar({ date, view, onToday, onNextDate, onPrevDate, onChangeView }) {
  const isDesktop = useResponsive('up', 'sm');
  const [shareApprovalPostsModal, setShareApprovalPostsModal] = useState(false);
  const [popoverElement, setPopoverElement] = useState(false);
  const [isLinkPresent, setLinkPresent] = useState(false);
  const [urlKey, setUrlKey] = useState('');
  const [shareUntil, setShareUntil] = useState('');
  const hasBrandAdminPermission = CommonHelper.CheckForPermissionAvailableForUser(
    CommonHelper.PermissionList.BRAND_ADMIN
  );
  const url = `${window.location.origin}/posts/${urlKey}`;
  const { showToast } = useToaster();

  useEffect(() => {
    hasBrandAdminPermission && getShareApprovalPosts();
  }, []);

  const getShareApprovalPosts = () => {
    const getProps = {
      url: userModuleURL.getShareApprovalPosts,
      successCallback: getShareApprovalPostsSuccessCallback,
      failureCallback: getShareApprovalPostsFailureCallback,
    };
    HttpServices.Get(getProps);
  };

  const getShareApprovalPostsSuccessCallback = (data) => {
    setLinkPresent(data?.is_present);
    setUrlKey(data?.url_key);
    setShareUntil(data?.expire_date);
  };

  const getShareApprovalPostsFailureCallback = (message) => {
    message && showToast(message, 'e');
  };

  const handleOpenMenu = (event) => {
    setPopoverElement(event.currentTarget);
    setShareApprovalPostsModal(true);
  };

  const handleCloseMenu = () => {
    setPopoverElement(null);
    setShareApprovalPostsModal(false);
  };
  const shareApprovalPostProps = {
    popoverElement: popoverElement,
    popoverElement,
    url: url,
    urlKey: urlKey,
    shareUntil: shareUntil,
    handleCloseMenu,
    getShareApprovalPosts,
  };

  return (
    <RootStyle>
      {isDesktop && (
        <Stack direction="row" spacing={0.5}>
          {VIEW_OPTIONS.map((viewOption) => (
            <Tooltip key={viewOption.value} title={viewOption.label}>
              <ToggleButton
                value={view}
                selected={viewOption.value === view}
                onChange={() => onChangeView(viewOption.value)}
                sx={{ width: 32, height: 32, padding: 0, border: 0 }}
              >
                {getViewIcon(viewOption.icon)}
              </ToggleButton>
            </Tooltip>
          ))}
        </Stack>
      )}

      <Stack direction="row" alignItems="center">
        <IconButton onClick={onPrevDate}>
          <ChevronLeftIcon/>
        </IconButton>

        <Typography variant="h6" sx={{ margin:'0 !important' }}>{date.toString()}</Typography>

        <IconButton onClick={onNextDate}>
          <ChevronRightIcon/>
        </IconButton>
      </Stack>
      <Stack direction="row" alignItems="flex-end" spacing={1}>
        {hasBrandAdminPermission && <Button size="small" variant='contained' startIcon={<ShareIcon />} onClick={(e) => handleOpenMenu(e)}>{isLinkPresent ? 'Link Already Shared' : 'Share Public Link'}</Button>}
        {isDesktop && (
          <Button size="small" color="error" variant="contained" onClick={onToday}>
            Today
          </Button>
        )}
      </Stack>
      {hasBrandAdminPermission && <SharePublicApprovalPopup {...shareApprovalPostProps} />}
    </RootStyle>
  );
}
