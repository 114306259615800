import { Box, Popover, Typography, CircularProgress, Stack } from '@mui/material';
import { PictureAsPdf, Image, Email } from '@mui/icons-material';
import { useState } from 'react';
import { useToaster } from 'src/utils/toaster/toasterContext';
import { reportModuleURL } from 'src/services/urlService';
import HttpServices from 'src/services/httpService';
import SendAsEmailComponent from './sendAsEmail.component';
import { useRightSidebar } from 'src/layouts/right-side-bar/rightSidebarProvider';
import React from 'react';
import ScheduledReportView from './scheduledReportView.component';

const ReportActionPopup = ({ open, anchorEl, onClose, serviceName, startDate, endDate, viewBy, connectedProfileId, reportId, subReportId = 0 ,currReportData}) => {
    const { showToast } = useToaster();
    const { showRightSidebar } = useRightSidebar();
    const [loading, setLoading] = useState(false);

    const successCallback = (data) => {
        setLoading(false);
        if (data?.report_url) {
            const link = document.createElement('a');
            link.href = data.report_url;
            link.download = ''; // Set a filename if needed, e.g., `report.pdf`
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        onClose(); // Close the popover after download starts
    };

    const failureCallback = (message) => {
        message && showToast(message);
        setLoading(false);
    };
    const handleOpenPost = () => {
        let data = {
            content:sideBarView,
        };
        showRightSidebar(data);
        onClose();
    };
    
    const sideBarView = () => {
        let emailDetails = {
            serviceName: serviceName,
            startDate: startDate,
            endDate:endDate,
            viewBy:viewBy,
            connectedProfileId:connectedProfileId,
            reportId:reportId,
            subReportId:subReportId,
            currReportData,
        };
        return (<SendAsEmailComponent {...emailDetails}/>);
    };
    const handleOpenSceduled = () => {
        let data = {
            content:sideBarSheduledView,
        };
        showRightSidebar(data);
        onClose();
    };
    
    const sideBarSheduledView = () => {
        let emailDetails = {
            serviceName: serviceName,
            startDate: startDate,
            endDate:endDate,
            viewBy:viewBy,
            connectedProfileId:connectedProfileId,
            reportId:reportId,
            subReportId:subReportId,
            currReportData,
        };
        return (<ScheduledReportView {...emailDetails}/>);
    };


    const getExportReport = (type) => {
        setLoading(true);
        const formBody = {
            from: startDate,
            to: endDate,
            view_by: viewBy,
            type: type,
            report_id: reportId,
            sub_report_id: subReportId,
            service_name: serviceName,
            connected_profile_id: connectedProfileId,
        };

        const getProps = {
            url: reportModuleURL.exportReport,
            body: formBody,
            successCallback,
            failureCallback,
        };
        HttpServices.Post(getProps);
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            PaperProps={{
                sx: {
                    width: 230,
                    borderRadius: 1,
                    boxShadow: 3,
                },
            }}
        >
            <Box sx={{ p: 1, textAlign: 'center' }}>
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%" sx={{p: 2}}>
                        <CircularProgress size={24} />
                    </Box>
                ) : (
                    <>
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            sx={{
                                cursor: 'pointer',
                                padding: '8px 16px',
                                borderRadius: 1,
                                '&:hover': { backgroundColor: 'action.hover' },
                                mb: 1,
                            }}
                            onClick={() => getExportReport("PDF")}
                        >
                            <PictureAsPdf color="action" />
                            <Typography variant="body1">Download as PDF</Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            sx={{
                                cursor: 'pointer',
                                padding: '8px 16px',
                                borderRadius: 1,
                                '&:hover': { backgroundColor: 'action.hover' },
                                mb: 1,
                            }}
                            onClick={() => getExportReport("PNG")}
                        >
                            <Image color="action" />
                            <Typography variant="body1">Download as PNG</Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            sx={{
                                cursor: 'pointer',
                                padding: '8px 16px',
                                borderRadius: 1,
                                '&:hover': { backgroundColor: 'action.hover' },
                            }}
                            onClick={handleOpenPost}
                        >
                            <Email color="action" />
                            <Typography variant="body1">Send as Email</Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            sx={{
                                cursor: 'pointer',
                                padding: '8px 16px',
                                borderRadius: 1,
                                '&:hover': { backgroundColor: 'action.hover' },
                            }}
                            onClick={handleOpenSceduled}
                        >
                            <Email color="action" />
                            <Typography variant="body1">Scheduled</Typography>
                        </Stack>
                    </>
                )}
            </Box>
        </Popover>
    );
};

export default ReportActionPopup;
