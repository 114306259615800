import { useTheme } from '@emotion/react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import HttpServices from 'src/services/httpService';
import { postsModuleURL } from 'src/services/urlService';
import { useToaster } from 'src/utils/toaster/toasterContext';
import FailedPostNestedGridBody from './FailedPostNestedGridBody.component';
import FailedPostNestedGridHead from './FailedPostNestedGridHead.component';



const FailedPostNestedGrid = ({ setSmallLoader, failedPostId, handleReCallFailedPost }) => {
    const { showToast } = useToaster();
    const theme = useTheme();
    useEffect(() => {
        if (failedPostId) {
            getFailedPost();
        }

    }, []);
    const [scheduledEventDetails, setScheduledEventDetails] = useState([]);


    const scheduleSuccessCallback = (data, message) => {
        message && showToast(message, "s");
        let failedEventDetails = []
        Object.keys(data.scheduled_event_requests_data).forEach(function (key) {
            data.scheduled_event_requests_data[key].forEach(function (event) {
                let failedEvent = {
                    id: event.id,
                    updated_at: event.updated_at,
                    description: event.description,
                    media_url: event.media_url,
                    failure_reason: event.failure_reason,
                    post_id: event.post_id,
                    connected_profile_details: [event.connected_profile_details]

                }
                failedEventDetails.push(failedEvent)
            })

        });
        setScheduledEventDetails(failedEventDetails);
        setSmallLoader(false)
    }
    const failureCallback = (message) => {
        message && showToast(message, "e");
        setSmallLoader(false)

    }
    const getFailedPost = () => {
        setSmallLoader(true)
        let getProps = {
            url: postsModuleURL.scheduledEventInfo + failedPostId,
            successCallback: scheduleSuccessCallback,
            failureCallback
        }
        HttpServices.Get(getProps);
    }


    return (
        <>
            {scheduledEventDetails && scheduledEventDetails.length > 0 &&
                <Box sx={{ margin: 1 }}>
                    <Typography variant="h6" gutterBottom component="div">
                        Failed Posts Details
                    </Typography>
                    <Table size="small" aria-label="purchases">
                        <FailedPostNestedGridHead></FailedPostNestedGridHead>
                        <FailedPostNestedGridBody failedPostId={failedPostId} scheduledEventDetails={scheduledEventDetails}></FailedPostNestedGridBody>
                    </Table>
                </Box>}
        </>
    );
}

export default FailedPostNestedGrid;

