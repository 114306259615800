import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  InputAdornment,
} from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const ScheduleReportView = () => {
  const [recipients, setRecipients] = useState([]);
  const [message, setMessage] = useState('');
  const [dateRange, setDateRange] = useState('Last 30 Days');
  const [groupBy, setGroupBy] = useState('Day');
  const [frequency, setFrequency] = useState('Daily');
  const [sendTime, setSendTime] = useState(new Date());
  const [format, setFormat] = useState('PDF');

  const handleSchedule = () => {
    console.log({
      recipients,
      message,
      dateRange,
      groupBy,
      frequency,
      sendTime,
      format,
    });
  };

  return (
    <Box sx={{ width: 500, p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Schedule Report
      </Typography>
      <Stack spacing={2}>
        {/* Recipients Field */}
        <TextField
          label="Who should receive this report?"
          placeholder="Type names for recipients"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography sx={{ bgcolor: '#E0E0E0', p: 0.5, borderRadius: 1 }}>
                  DE Demo 081
                </Typography>
              </InputAdornment>
            ),
          }}
          fullWidth
        />

        {/* Message Field */}
        <TextField
          label="Send a message with your report?"
          placeholder="Enter your message"
          multiline
          rows={3}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          fullWidth
        />

        {/* Date Range Field */}
        <Box>
          <Typography variant="body1">Date range</Typography>
          <Select
            value={dateRange}
            onChange={(e) => setDateRange(e.target.value)}
            fullWidth
          >
            <MenuItem value="Last 7 Days">Last 7 Days</MenuItem>
            <MenuItem value="Last 30 Days">Last 30 Days</MenuItem>
            <MenuItem value="Last 90 Days">Last 90 Days</MenuItem>
          </Select>
        </Box>

        {/* Group By Field */}
        <Box>
          <Typography variant="body1">Group data by</Typography>
          <Select
            value={groupBy}
            onChange={(e) => setGroupBy(e.target.value)}
            fullWidth
          >
            <MenuItem value="Day">Day</MenuItem>
            <MenuItem value="Week">Week</MenuItem>
            <MenuItem value="Month">Month</MenuItem>
          </Select>
        </Box>

        {/* Frequency Field */}
        <Box>
          <Typography variant="body1">How often should we send it out?</Typography>
          <Select
            value={frequency}
            onChange={(e) => setFrequency(e.target.value)}
            fullWidth
          >
            <MenuItem value="Daily">Daily</MenuItem>
            <MenuItem value="Weekly">Weekly</MenuItem>
            <MenuItem value="Monthly">Monthly</MenuItem>
          </Select>
        </Box>

        {/* Send Time Field */}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box>
            <Typography variant="body1">Choose a send time?</Typography>
            <TimePicker
              value={sendTime}
              onChange={(newValue) => setSendTime(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Box>
        </LocalizationProvider>

        {/* Format Field */}
        <Box>
          <Typography variant="body1">What format would you prefer?</Typography>
          <Select
            value={format}
            onChange={(e) => setFormat(e.target.value)}
            fullWidth
          >
            <MenuItem value="PDF">PDF</MenuItem>
            <MenuItem value="Excel">Excel</MenuItem>
          </Select>
        </Box>

        <Divider />

        {/* Actions */}
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button variant="outlined">Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleSchedule}>
            Schedule
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ScheduleReportView;
